export const userColumns = [
    {
      Header: 'Name',
      accessor: 'Name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phonenumber',
      accessor: 'phonenumber',
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
    {
      Header: 'Active',
      accessor: 'ratePerQuantity',
    }
  ];