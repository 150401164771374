import React, { useEffect, useState } from 'react';
import { MdDelete, MdDownload, MdOutlineMoreHoriz } from 'react-icons/md';
import { IoIosPrint } from 'react-icons/io';
import '../TableComponent/tablecomponent.css'
import { toast } from 'sonner';
import axios from 'axios';
import { AiOutlineCaretRight } from "react-icons/ai";
import { API_URL } from '../APIConstants';

function PaymentTable({
    currentInvoices,
    expandedRowIndex,
    setExpandedRowIndex,
    expandedActionRowIndex,
    setExpandedActionRowIndex,
    onPrint,
    onDownload,
    selectedInvoiceId,
    componentRef,
    handlePrintReady,
    indexOfFirstInvoice,
    indexOfLastInvoice,
    filteredInvoices,
    setCurrentPage,
    totalPages,
    currentPage,
    formatAmount,
    getPaymentStatus = (status) => ({ text: status, color: 'black' }),
    editablePaidAmount = false,
    paidAmounts = {},
    setPaidAmounts,
    finalAmount,
    paymentList,
    isThisInvoice,
    callapsableTable
}) {
    const [totalPrevAmountPaid, setTotalPrevAmountPaid] = useState(0);
    const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
    const [totalPaidAmount, setTotalPaidAmount] = useState(0);
    const [totalBalanceAmount, setTotalBalanceAmount] = useState(0);
    const [totalNetAmount, setTotalNetAmount] = useState(0);
    const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);

    const [initialBalances, setInitialBalances] = useState({});

    const [expandedInvoiceData, setExpandedInvoiceData] = useState({});
    const [loadingInvoiceData, setLoadingInvoiceData] = useState(false);
    const [errorInvoiceData, setErrorInvoiceData] = useState(null);

    function formatIndianNumber(amount) {
        const [integerPart, decimalPart] = amount.toString().split('.');

        let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        formattedInteger = formattedInteger.replace(/\d(?=(\d{2})+\d{3},)/g, '$&,');

        return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
    }

    useEffect(() => {
        const balances = {};
        currentInvoices.forEach((invoice) => {
            balances[invoice.invoiceId] = invoice.invoiceAmount - (invoice.previousAmountPaid || 0);
        });
        setInitialBalances(balances);
    }, [currentInvoices]);

    const handleActionRowClick = (index) => {
        setExpandedActionRowIndex(expandedActionRowIndex === index ? null : index);
    };

    const handleRowClick = async (index, invoiceId) => {
        if (expandedRowIndex === index) {
            setExpandedRowIndex(null);
        } else {
            setExpandedRowIndex(index);

            if (!expandedInvoiceData[invoiceId]) {
                setLoadingInvoiceData(true);
                setErrorInvoiceData(null);
                try {
                    const response = await axios.get(API_URL + `/Invoice/GetInvoiceById?id=${invoiceId}`);
                    setExpandedInvoiceData((prevData) => ({
                        ...prevData,
                        [invoiceId]: response.data,
                    }));
                } catch (error) {
                    console.error('Error fetching invoice data:', error);
                    setErrorInvoiceData('Failed to load invoice details.');
                } finally {
                    setLoadingInvoiceData(false);
                }
            }
        }
    };

    useEffect(() => {
        calculateTotals();

        const total = currentInvoices.reduce((acc, invoice) => acc + invoice.totalAmountNet, 0);
        const totalPayment = currentInvoices.reduce((acc, invoice) => acc + invoice.amount, 0);
        setTotalNetAmount(total);
        setTotalPaymentAmount(totalPayment)
    }, [paidAmounts, currentInvoices]);

    const handlePaidAmountChange = (invoiceId, value) => {
        const numericValue = parseFloat(value);
        const tolerance = 0.01;
        const originalBalance = initialBalances[invoiceId];

        if (!isNaN(numericValue) && numericValue >= 0) {
            if (numericValue <= originalBalance + tolerance) {
                const newTotalPaid = Object.values(paidAmounts).reduce((sum, amount) => sum + amount, 0) - (paidAmounts[invoiceId] || 0) + numericValue;

                if (newTotalPaid <= finalAmount) {
                    setPaidAmounts((prev) => ({
                        ...prev,
                        [invoiceId]: numericValue,
                    }));
                } else {
                    toast.warning('Paid amount exceeds the final amount limit.');
                    setPaidAmounts((prev) => ({
                        ...prev,
                        [invoiceId]: 0,
                    }));
                }
            } else {
                toast.warning('Paid amount exceeds the original balance amount.');
                setPaidAmounts((prev) => ({
                    ...prev,
                    [invoiceId]: 0,
                }));
            }
        } else if (value === '') {
            setPaidAmounts((prev) => ({
                ...prev,
                [invoiceId]: '',
            }));
        }

        calculateTotals();
    };

    const calculateTotals = () => {
        let totalPrevAmount = 0;
        let totalInvoice = 0;
        let totalPaid = 0;
        let totalBalance = 0;

        currentInvoices.forEach((invoice) => {
            const paid = paidAmounts[invoice.invoiceId] || 0;
            const balance = parseFloat((invoice.invoiceAmount - paid).toFixed(2));

            totalPrevAmount += invoice.previousAmountPaid || 0;
            totalInvoice += invoice.invoiceAmount || 0;
            totalPaid += paid;
            totalBalance += balance;
        });

        setTotalPrevAmountPaid(totalPrevAmount);
        setTotalInvoiceAmount(totalInvoice);
        setTotalPaidAmount(totalPaid);
        setTotalBalanceAmount(totalBalance);

        if (totalPaid > finalAmount) {
            toast.warning('You are exceeding your final amount limit.');
        }
    };

    return (
        <>
            <div className='table-container'>
                <table id="example2" className="table table-hover" >
                    <thead>
                        <tr>
                        {callapsableTable ? <th></th> : null }

                            {editablePaidAmount ? (
                                ''
                            ) : (
                                <th>Cust. Name</th>

                            )}

                            {paymentList ? <th>Payment Number</th> : <th>Invoice Number</th> }

                            {editablePaidAmount ? (
                                <th>Invoice Date</th>
                            ) : (
                                <th>Invoice Date</th>

                            )}

                            <th style={{ textAlign: 'right' }}>Invoice Amt (₹)</th>
                            {editablePaidAmount ? <th style={{ textAlign: 'right' }}>Prev. Amt. Paid</th> : null }
                            {editablePaidAmount ? <th style={{ textAlign: 'center' }}>Paid Amt (₹)</th> : ( <>{paymentList ? null : <th style={{ textAlign: 'center' }}>Payment Status</th>} </>)}
                            <th style={{ textAlign: 'right' }}>Balance Amt (₹)</th>
                            {!editablePaidAmount && !paymentList ? <th>Action</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {currentInvoices.length === 0 ? (
                            <tr>
                                <td colSpan="6" className="text-center">No invoices found.</td>
                            </tr>
                        ) : (
                            currentInvoices.map((invoice, index) => {
                                const paymentStatus = getPaymentStatus(invoice.paymentStatus);
                                const balanceAmount = invoice.invoiceAmount - (paidAmounts[invoice.invoiceId] || 0);
                                const isExpanded = expandedActionRowIndex === index;
                                const isRowExpanded = expandedRowIndex === index;

                                const invoiceDetails = expandedInvoiceData[invoice.invoiceId];

                                return (
                                    <React.Fragment key={invoice.invoiceId}>
                                        <tr>
                                            {callapsableTable ? <td><AiOutlineCaretRight className={`collapsible-icon ${isRowExpanded ? 'collapsible-rotate' : ""}`}  onClick={() => handleRowClick(index, invoice.invoiceId)}/></td> : null}

                                            {editablePaidAmount ? (
                                                ''
                                            ) : (
                                                <td>{invoice.customerName}</td>

                                            )}
                                            {paymentList ? <td>{invoice.paymentNo}</td> : <td>{invoice.invoiceNumber}</td>}

                                            {paymentList ? <td>{new Date(invoice.paymentDate).toLocaleDateString()}</td> : <td>{new Date(invoice.invoiceDate).toLocaleDateString()}</td>}


                                            {editablePaidAmount ? (
                                                <td style={{ textAlign: 'right' }}>{formatIndianNumber(formatAmount(invoice.invoiceAmount))}</td>

                                            ) : (
                                                <>

                                                {paymentList ? <td style={{ textAlign: 'right' }}>{formatIndianNumber(formatAmount(invoice.amount))}</td> : <td style={{ textAlign: 'right' }}>{formatAmount(invoice.totalAmountNet)}</td>}
                                                </>

                                            )}

                                            {editablePaidAmount ? <td style={{ textAlign: 'right' }}>{formatIndianNumber(formatAmount(invoice.previousAmountPaid))}</td> : null}


                                            {editablePaidAmount ? (
                                                    <td style={{ textAlign: 'center' }}>
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            max={balanceAmount}
                                                            placeholder="0.00"
                                                            value={paidAmounts[invoice.invoiceId] || ''}
                                                            onChange={(e) => handlePaidAmountChange(invoice.invoiceId, e.target.value)}
                                                            style={{ width: '80px', height: "24px", textAlign: 'right' }}
                                                        />
                                                    </td>
                                                ) : (
                                                    <>
                                                        {paymentList ? null : (
                                                            <td style={{ textAlign: 'center', color: paymentStatus.color, fontWeight: 'bold' }}>
                                                                {paymentStatus.text}
                                                            </td>
                                                        )}
                                                    </>
                                                )}

                                            {editablePaidAmount ? (
                                                <td style={{ textAlign: 'right' }}>{formatIndianNumber(balanceAmount.toFixed(2))}</td>

                                            ) : (
                                                <>

                                                {paymentList ? <td style={{ textAlign: 'right' }}>{formatIndianNumber(formatAmount(invoice.amount))}</td> : <td style={{ textAlign: 'right' }}>{formatAmount(invoice.totalAmountNet)}</td>}
                                                </>

                                            )}


                                            {!editablePaidAmount && !paymentList ? (
                                                <td onClick={() => handleActionRowClick(index)} className="actions text-center" style={{ cursor: 'pointer' }}>
                                                    <MdOutlineMoreHoriz size={18} />
                                                    {isExpanded && (
                                                        <div className="row-actions">
                                                            <button onClick={() => onPrint(invoice.invoiceId)}>
                                                                <IoIosPrint /> Print
                                                            </button>
                                                            <button onClick={() => onDownload(invoice.invoiceId)}>
                                                                <MdDownload /> Download
                                                            </button>
                                                            <button onClick={() => console.log('Delete action triggered')}>
                                                                <MdDelete /> Delete
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            ) : null}
                                        </tr>
                                        {isRowExpanded && (
                                                <tr>
                                                    <td colSpan={editablePaidAmount ? 7 : 8}>
                                                        {loadingInvoiceData ? (
                                                            <div>Loading...</div>
                                                        ) : errorInvoiceData ? (
                                                            <div style={{ color: 'red' }}>{errorInvoiceData}</div>
                                                        ) : invoiceDetails ? (
                                                            <div className="expanded-content">
                                                                <h5>Invoice Details</h5>
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Product Name</th>
                                                                            <th>Unit Price (₹)</th>
                                                                            <th>Quantity</th>
                                                                            <th>Discount (%)</th>
                                                                            <th>Discount Amount (₹)</th>
                                                                            <th >Total Amount (₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {invoiceDetails.invoiceDetails.map((detail) => (
                                                                            <tr key={detail.invoiceDetailId}>
                                                                                <td>{detail.productName}</td>
                                                                                <td style={{ textAlign: 'right' }}>{formatIndianNumber(formatAmount(detail.unitPrice))}</td>
                                                                                <td style={{ textAlign: 'center' }}>{detail.quantity}</td>
                                                                                <td style={{ textAlign: 'right' }}>{detail.discountPercentage}%</td>
                                                                                <td style={{ textAlign: 'right' }}>{formatIndianNumber(formatAmount(detail.discounAmount))}</td>
                                                                                <td style={{ textAlign: 'right' }}>{formatIndianNumber(formatAmount(detail.totalAmount))}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr>
                                                                            <th colSpan="5" style={{ textAlign: 'left' }}>Total</th>
                                                                            <th style={{ textAlign: 'right' }}>
                                                                                {formatIndianNumber(
                                                                                    invoiceDetails.invoiceDetails.reduce((acc, detail) => acc + detail.totalAmount, 0)
                                                                                )}
                                                                            </th>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>

                                                            </div>
                                                        ) : (
                                                            <div>No details available.</div>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}

                                    </React.Fragment>
                                );
                            })
                        )}
                    </tbody>
                <tfoot>
                <tr>
                    <td colSpan={isThisInvoice ? 4 : editablePaidAmount ? 2 : 3} style={{ textAlign: 'start', fontWeight: 'bold' }}>Total:</td>


                    {!isThisInvoice && editablePaidAmount && (
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatIndianNumber(formatAmount(totalInvoiceAmount))}</td>
                    )}
                    {!isThisInvoice && editablePaidAmount && (
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatIndianNumber(formatAmount(totalPrevAmountPaid))}</td>
                    )}

                    {!isThisInvoice && !editablePaidAmount && (
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatIndianNumber(totalPaymentAmount.toFixed(2))}</td>
                    )}

                    {!isThisInvoice && !editablePaidAmount && (
                        <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatIndianNumber(totalPaymentAmount.toFixed(2))}</td>
                    )}

                    {isThisInvoice && (
                        <td colSpan={isThisInvoice ? 0 : 1 } style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatIndianNumber(totalNetAmount.toFixed(2))}</td>
                    )}

                    {!isThisInvoice && editablePaidAmount && (
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{formatIndianNumber(formatAmount(totalPaidAmount))}</td>
                    )}

                    {isThisInvoice && (
                        <td colSpan={isThisInvoice ? 2 : 1 }  style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatIndianNumber(totalNetAmount.toFixed(2))}</td>
                    )}



                    {!isThisInvoice && editablePaidAmount && (
                    <td style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatIndianNumber(formatAmount(totalBalanceAmount))}</td>
                    )}

                    {!editablePaidAmount && <td></td>}
                    </tr>

                </tfoot>
            </table>
            </div>

            {/* Pagination */}
            <div className="pagination justify-content-end mt-4">
                <button
                    onClick={() => setCurrentPage(1)}
                    className={`btn ${currentPage === 1 ? 'btn-primary' : 'btn-light'}`}
                    disabled={currentPage === 1}
                >
                    First
                </button>
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="btn btn-light"
                    disabled={currentPage === 1}
                >
                    &lt; Previous
                </button>
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentPage(index + 1)}
                        className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className="btn btn-light"
                    disabled={currentPage === totalPages}
                >
                    Next &gt;
                </button>
                <button
                    onClick={() => setCurrentPage(totalPages)}
                    className={`btn ${currentPage === totalPages ? 'btn-primary' : 'btn-light'}`}
                    disabled={currentPage === totalPages}
                >
                    Last
                </button>
            </div>
        </>
    );
}

export default PaymentTable;
