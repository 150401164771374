import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCrmCustomer, updateExistingCrmCustomer } from '../../Store/Slices/crmCustomerAction';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../CommonComp/APIConstants';



function AddCrmCustomer() {

  const { customerId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { crmCustomers } = useSelector((state) => state.crmCustomers);
  
  const findCustomerById = (id) => {
    if (id) {
      return crmCustomers.find(customer => customer.id === id.toString().toLowerCase());
    }
    else {
      return {
        referenceId: '',
        other: '',
        companyName: '',
        firstName: '',
        lastName: '',
        phoneNumber1: '',
        phoneNumber2: '',
        email: '',
        website: '',
        category: '',
        categoryId: '',
        addressLine1: '',
        addressLine2: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        note: ''
      }
    }
  }

  const customer = findCustomerById(customerId); 
  const [updatedData, setUpdatedData] = useState({
    id: customerId ? customerId : null,
    referenceId: customer.referenceId,
    other: customer.other,
    companyName: customer.companyName,
    firstName: customer.firstName,
    lastName: customer.lastName,
    phoneNumber1: customer.phoneNumber1,
    phoneNumber2: customer.phoneNumber2,
    email: customer.email,
    website: customer.website,
    category: customer.category,
    categoryId: customer.categoryId,
    addressLine1: customer.addressLine1,
    addressLine2: customer.addressLine2,
    contactPersonFirstName: customer.contactPersonFirstName,
    contactPersonLastName: customer.contactPersonLastName,
    note: customer.note
  });

   const [referencesData, setReferencesData] = useState([]);
   const [categoriesData, setCategoriesData] = useState([]);
   const [showOtherTextField, setShowOtherTextField] = useState(false);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetchReferencesData();
    fetchCategoriesData();
  }, []);

  const fetchReferencesData = async () => {
    try {
      const response = await axios.get(`${API_URL}/CrmCustomer/getreferencelist`);
      const data = await response.json();
      setReferencesData(data);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  const fetchCategoriesData = async () => {
    try {
      const response = await fetch(`${API_URL}/CrmCustomer/getcategorylist`); // Replace with your API endpoint
      const data = await response.json();
      setCategoriesData(data);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  const handleReferenceChange = (event) => {
    event.preventDefault();
    setUpdatedData({ ...updatedData, [event.target.name]: event.target.value });

    const selectedReference = referencesData.find(option => option.referenceId === event.target.value);
    if (selectedReference) {
      if (selectedReference.referenceName.toString().toLowerCase() == "other" ||
        selectedReference.referenceName.toString().toLowerCase() == "expo") {
         setShowOtherTextField(true);
      } else {
         setShowOtherTextField(false);
      }
    }
  };

  const handleCategoryChange  = (event) => {
    event.preventDefault();
    setUpdatedData({...updatedData, [event.target.name]: event.target.value});

    // const selectedCategory = categoriesData.find(option => option.categoryId === event.target.value);
    // if (selectedCategory) {
    //   if (selectedCategory.categoryName.toString().toLowerCase() == "other" ||
    //     selectedCategory.categoryName.toString().toLowerCase() == "expo") {
    //      setShowOtherTextField(true);
    //   } else {
    //      setShowOtherTextField(false);
    //   }
    // }
  }

    const handleChange = (e) => {
      console.log("e.target.name:", e.target.name);
      console.log("e.target.value:", e.target.value);
    e.preventDefault();
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  //Save/update the customer data.
  const handleSubmit = async (e) => {
    e.preventDefault();
    let customerData;
    if (customerId) {
      dispatch(updateExistingCrmCustomer(updatedData));
    } else {
      dispatch(addNewCrmCustomer(updatedData));
    }
    navigate("/crm_customer_master");
  };

  return (
    <div>

      <div className="content-wrapper">
        <div className="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h2>Customer</h2>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="/customer_master">Customers</a></li>
                <li class="breadcrumb-item active">Add Customer</li>
              </ol>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">CRM Customer Details</h3>
                <div className="card-tools">
                  <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus" />
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times" />
                  </button>
                </div>
              </div>
              <div className="card-body">

                {/* Reference, Other */}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Select Reference</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <div className="input-group-append">
                        <select
                          className="form-control select2"
                          style={{ width: '100%' }}
                          name="referenceId"
                          value={updatedData.referenceId}
                          onChange={handleReferenceChange}
                        >
                          <option value="">Select Reference</option>
                          {referencesData.map((reference) => (
                            <option key={reference.referenceId} value={reference.referenceId}>
                              {reference.referenceName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {showOtherTextField && (
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Other</label>
                      </div>
                    </div>
                  )}
                  {showOtherTextField && (
                    <div className="col-md-4">
                      <div className="form-group">
                        <input type="text"
                          name="other"
                          value={updatedData.other}
                          onChange={handleChange}
                          className="form-control" id="custEmailTxt"
                          placeholder="Enter Other Reference" />
                      </div>
                    </div>
                  )}
                </div>

                {/* Company Name */}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Company Name</label>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        value={updatedData.companyName}
                        onChange={handleChange}
                        id="custNameTxt"
                        placeholder="Enter Company Name" />
                    </div>
                  </div>
                </div>

                {/* First name, last Name */}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>First Name</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={updatedData.firstName}
                        onChange={handleChange}
                        id="custNameTxt"
                        placeholder="Enter First Name" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Last Name</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input type="text"
                        name="lastName"
                        value={updatedData.lastName}
                        onChange={handleChange}
                        className="form-control" id="custEmailTxt"
                        placeholder="Enter Last Name" />
                    </div>
                  </div>
                </div>

                {/* Phonenumber1, Phonenumber2 */}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Phone No.</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="phoneNumber1"
                        value={updatedData.phoneNumber1}
                        onChange={handleChange}
                        id="custNameTxt"
                        placeholder="Enter Phonenumber" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Mobile No.</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input type="text"
                        name="phoneNumber2"
                        value={updatedData.phoneNumber2}
                        onChange={handleChange}
                        className="form-control" id="custEmailTxt"
                        placeholder="Enter Alternate Phonenumber" />
                    </div>
                  </div>
                </div>

                {/* Email, Website */}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Email</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={updatedData.email}
                        onChange={handleChange}
                        id="custNameTxt"
                        placeholder="Enter Email" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Website</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input type="text"
                        name="website"
                        value={updatedData.website}
                        onChange={handleChange}
                        className="form-control" id="custEmailTxt"
                        placeholder="Enter Website" />
                    </div>
                  </div>
                </div>

                {/* Category */}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Category</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <div className="input-group-append">
                        <select
                          className="form-control select2"
                          style={{ width: '100%' }}
                          name="categoryId"
                          value={updatedData.categoryId}
                          onChange={handleCategoryChange}
                        >
                          <option value="">Select Category</option>
                          {categoriesData.map((category) => (
                            <option key={category.categoryId} value={category.categoryId}>
                              {category.categoryName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                  </div>
                  <div className="col-md-4">
                  </div>
                </div>

                {/* Address Line 1, Address line 2 */}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Address Line 1</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="addressLine1"
                        value={updatedData.addressLine1}
                        onChange={handleChange}
                        id="custNameTxt"
                        placeholder="Enter Address Line 1" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Address Line 2</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input type="text"
                        name="addressLine2"
                        value={updatedData.addressLine2}
                        onChange={handleChange}
                        className="form-control" id="custEmailTxt"
                        placeholder="Enter Address Line 2" />
                    </div>
                  </div>
                </div>

                {/* First name, last Name */}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Conatct Person First Name</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="contactPersonFirstName"
                        value={updatedData.contactPersonFirstName}
                        onChange={handleChange}
                        id="custNameTxt"
                        placeholder="Enter First Name" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Conatct Person Last Name</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input type="text"
                        name="contactPersonLastName"
                        value={updatedData.contactPersonLastName}
                        onChange={handleChange}
                        className="form-control" id="custEmailTxt"
                        placeholder="Enter Last Name" />
                    </div>
                  </div>
                </div>

                {/* Notes */}
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Notes</label>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="form-group">

                      <textarea
                        type="textarea"
                        className="form-control"
                        name="note"
                        value={updatedData.note}
                        onChange={handleChange}
                        id="custNameTxt"
                        placeholder="Enter Notes" />
                    </div>
                  </div>

                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-md-10">
              </div>
              <div className="col-md-1">
                <button type="submit" class="btn btn-block btn-success">Save</button>
              </div>
              <div className="col-md-1">
                <button type="button" class="btn btn-block btn-danger">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

}

export default AddCrmCustomer;