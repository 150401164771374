import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import AppConfig from '../../config';
import { Link } from 'react-router-dom';
import AddProcessBOM from './AddProcessBOM';
import { API_URL } from '../../CommonComp/APIConstants';
import { productColumns } from '../../CommonComp/TableComponent/EntityTables/ProductTblColumns';
import TableComponent from '../../CommonComp/TableComponent/TableComponent';
import { toast } from 'sonner';
import { deleteProductData, getProductsList } from '../../service/ProductService';

function Products() {

    const navigate = useNavigate();
    const [productData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const addProduct = (e) => {
        e.preventDefault();
        navigate("/add_product");
    }

    useEffect(() => {
        fetchProductData();
    }, []);

    const fetchProductData = async () => {
        try {
            const response = await getProductsList();
            setData(response);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    const handleView = (productId) => {
        navigate(`/product_master/view/${productId}`);
    };

    const handleEdit = (productId) => {
        navigate(`/product_master/edit/${productId}`);
    };

    const handleDelete = async (productId) => {
        try {
            await deleteProductData(productId);
            setData(prevData => prevData.filter(product => product.productId !== productId));
            toast.success("Product Deleted Successfully");
        } catch (error) {
            console.error("Failed to delete product", error);
            toast.error("Failed to delete product.", error);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading products.</div>;

    return (
        <div>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h2>Product</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-11">
                                        </div>
                                        <div className="col-md-1">
                                            <button
                                                style={{ marginBottom: '10px' }}
                                                type="button"
                                                onClick={addProduct}
                                                className="btn btn-block btn-success">Add
                                            </button>
                                        </div>
                                    </div>
                                    <TableComponent
                                        columns={productColumns}
                                        data={productData}
                                        idAccessor="productId"
                                        onView={handleView}
                                        onEdit={handleEdit}
                                        onDelete={handleDelete}
                                     />
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </div>

                {/* <AddProcessBOM
             data={options}
             rowClick={(suppliers) => handleRowClickSupplierModal(suppliers)}
             selectedRow={selectedRow}
            /> */}

            </div>
        </div>
    );
}

export default Products;