import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FormControl, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { API_URL } from '../../CommonComp/APIConstants';
import './payment.css';
import PaymentTable from '../../CommonComp/invoiceandpaymenttable/PaymentTable';
import { getPaymentList } from '../../service/PaymentService';
import { getCustomerList } from '../../service/CustomerService';

function Payments() {
    const navigate = useNavigate();

    const [paymentData, setPaymentData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [dateRange, setDateRange] = useState("thisMonth");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState("");




    useEffect(() => {
        setLoading(true);

        getPaymentList()
            .then(response => {
                console.log("API Response: ", response);
                setPaymentData(response);
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error fetching the payment data!", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        setFromDate(startOfMonth.toISOString().split('T')[0]);
        setToDate(today.toISOString().split('T')[0]);
    }, []);

    const formatAmount = (amount) => {
        if (typeof amount === 'number') {
            return amount.toLocaleString('en-IN');
        }
        return amount;
    };

    useEffect(() => {
        getCustomerList()
            .then(customerData => setCustomers(customerData))
            .catch(error => console.error("Error fetching customers!", error));
    }, []);

    const handleDateRangeChange = (e) => {
        const selectedRange = e.target.value;
        setDateRange(selectedRange);

        const today = new Date();

        switch (selectedRange) {
            case "today":
                setFromDate(today.toISOString().split('T')[0]);
                setToDate(today.toISOString().split('T')[0]);
                break;
            case "thisWeek":
                const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
                setFromDate(startOfWeek.toISOString().split('T')[0]);
                setToDate(new Date().toISOString().split('T')[0]);
                break;
            case "thisMonth":
                const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                setFromDate(startOfMonth.toISOString().split('T')[0]);
                setToDate(new Date().toISOString().split('T')[0]);
                break;
            case "lastMonth":
                const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                setFromDate(startOfLastMonth.toISOString().split('T')[0]);
                setToDate(endOfLastMonth.toISOString().split('T')[0]);
                break;
            case "thisYear":
                const startOfYear = new Date(today.getFullYear(), 0, 1);
                setFromDate(startOfYear.toISOString().split('T')[0]);
                setToDate(new Date().toISOString().split('T')[0]);
                break;
            case "lastYear":
                const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
                const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
                setFromDate(startOfLastYear.toISOString().split('T')[0]);
                setToDate(endOfLastYear.toISOString().split('T')[0]);
                break;
            case "custom":
                setFromDate("");
                setToDate("");
                break;
            default:
                break;
        }
    };

    const filteredInvoices = (paymentData || []).filter(invoice => {
        const invoiceDate = new Date(invoice.paymentDate);
        const isDateInRange = invoiceDate >= new Date(fromDate) && invoiceDate <= new Date(toDate);
        const isCustomerMatching = selectedCustomer === "" || invoice.customerId === selectedCustomer;
        const isSearchQueryMatching =
            invoice.customerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invoice.invoiceNumber?.toLowerCase().includes(searchQuery.toLowerCase());

        return isDateInRange && isCustomerMatching && isSearchQueryMatching;
    });

    // console.log(paymentData)


    const invoicesPerPage = 15;

    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);
    const totalPages = Math.ceil(filteredInvoices.length / invoicesPerPage);


    const handleRowClick = (index) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const addPayment = (e) => {
        e.preventDefault();
        navigate("/add_payment");
    };

    const getPaymentStatus = (status) => {
        switch (status) {
            case 0: return { text: "Pending", color: "red" };
            case 1: return { text: "Partial Paid", color: "orange" };
            case 2: return { text: "Fully Paid", color: "green" };
            case 3: return { text: "Settled", color: "blue" };
            default: return { text: "Unknown", color: "black" };
        }
    };

    const handleCustomerChange = (e) => {
        const customerId = e.target.value;
        if (customerId === "all") {
            setSelectedCustomer("");
        } else {
            setSelectedCustomer(customerId);
        }
    };

    console.log(currentInvoices)



    return (
        <div className="content-wrapper">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h2>Payments</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="row mb-2">
                                <div className="col-md-10 d-flex align-items-center">
                                    <div className="mr-1">
                                        <label htmlFor="dateRange">Date Range</label>
                                        <select
                                            id="dateRange"
                                            value={dateRange}
                                            onChange={handleDateRangeChange}
                                            className="range-dropdown form-control w-auto"
                                        >
                                            <option value="today">Today</option>
                                            <option value="thisWeek">This Week</option>
                                            <option value="thisMonth">This Month</option>
                                            <option value="lastMonth">Last Month</option>
                                            <option value="thisYear">This Year</option>
                                            <option value="lastYear">Last Year</option>
                                            <option value="custom">Custom</option>
                                        </select>
                                    </div>

                                    <div className="mr-1">
                                        <label htmlFor="fromDate">From Date</label>
                                        <FormControl
                                            id="fromDate"
                                            type="date"
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e.target.value)}
                                            className="date-input payment-input"
                                            disabled={dateRange !== "custom"}
                                        />
                                    </div>

                                    <div className="mr-1">
                                        <label htmlFor="toDate">To Date</label>
                                        <FormControl
                                            id="toDate"
                                            type="date"
                                            value={toDate}
                                            onChange={(e) => setToDate(e.target.value)}
                                            className="date-input payment-input "
                                            disabled={dateRange !== "custom"}
                                        />
                                    </div>

                                    <div className="mr-2">
                                        <label htmlFor="customer">Customer</label>
                                        <select
                                            id="customer"
                                            value={selectedCustomer}
                                            onChange={handleCustomerChange}
                                            className="addpay-dropdown form-control w-auto"
                                            style={{ minWidth: "180px", maxWidth: "180px" }}
                                        >
                                            <option value="all">All Customers</option>
                                            {customers.map((customer) => (
                                                <option key={customer.customerId} value={customer.customerId}>
                                                    {customer.customerName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>


                                    <div className="ml-1 w-10">
                                        <label htmlFor="searchQuery">Search</label>
                                        <FormControl
                                            id="searchQuery"
                                            placeholder="Search by Name, Code, PAN"
                                            value={searchQuery}
                                            onChange={handleSearch}
                                            className="date-input payment-input"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2 text-right d-flex">
                                    <Button onClick={addPayment} className="btn-success my-auto ml-auto">Add Payment</Button>
                                </div>
                            </div>

                            {loading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : (
                                <>
                                    <PaymentTable
                                        currentInvoices={currentInvoices}
                                        indexOfFirstInvoice={indexOfFirstInvoice}
                                        indexOfLastInvoice={indexOfLastInvoice}
                                        filteredInvoices={filteredInvoices}
                                        expandedRowIndex={expandedRowIndex}
                                        setExpandedRowIndex={setExpandedRowIndex}
                                        setCurrentPage={setCurrentPage}
                                        totalPages={totalPages}
                                        currentPage={currentPage}
                                        formatAmount={formatAmount}
                                        getPaymentStatus={getPaymentStatus}
                                        paymentList = {true}
                                    />


                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Payments;
