import { useNavigate } from 'react-router-dom';
import './loginpage.css';
import { toast } from 'sonner';
import { useState, useContext } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import LoginBanner from './login_bannner.png';
import Logo from './connect_logo.png';
import Spinner from '../../CommonComp/spinners/Spinner';
import { AuthContext } from '../../context/AuthContext';
import { API_URL } from '../../CommonComp/APIConstants';

function LoginPage() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    userName: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(API_URL + '/Login/Login', formData);

      if (response.status === 200 && response.data.access_token) {
        const token = response.data.access_token;

        let decodedToken;
        try {
          decodedToken = jwtDecode(token);
        } catch (error) {
          throw new Error('Token decoding failed.');
        }

        login(decodedToken);

        toast.success('Login successful!', { variant: 'success' });
        navigate('/dashboard');
      } else {
        toast.error('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error('An error occurred. Please try again.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-banner">
        <img src={LoginBanner} alt="login-banner" />
      </div>

      <div className="login-holder">
        <div className="login-card">
          <img src={Logo} alt="logo" />
          <h5>Login Form</h5>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="userName"
              placeholder="Username"
              value={formData.userName}
              onChange={handleChange}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <p>Forgot Password?</p>
            <button type="submit" disabled={loading}>
              {loading ? <Spinner /> : 'Submit'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
