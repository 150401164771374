import React from 'react';
import { FcEmptyTrash } from 'react-icons/fc';
import './formtable.css'

const FormTable = ({
  invProdListItemsData,
  subTotalAmount = 0,
  discTotalAmount = 0,
  taxTotalAmount = 0,
  grandTotalAmount = 0,
  updateQuantityAmount,
  updateDiscount,
  updateUnitCost,
  updateDiscountAmount,
  removeProduct,
  createSaleInvoice,
  showTotalTax,
  additionalActions,
}) => {
  const handleDiscountAmountChange = (index, discountAmount) => {
    const product = invProdListItemsData[index];
    const discountPercentage = ((discountAmount / product.totalProductValue) * 100).toFixed(2);
    updateDiscountAmount(index, discountAmount, discountPercentage);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12" style={{ maxHeight: "200px", overflowY: "scroll", minHeight: "270px" }}>
          <table id="example2" className="table table-bordered table-hover">
            <thead>
              <tr>
                <th style={{ width: '30%' }}>Product Name</th>
                <th style={{ width: '8%' }}>Qty</th>
                <th style={{ width: '10%' }}>Unit cost(₹)</th>
                <th style={{ width: '13%' }}>Product Value</th>
                <th style={{ width: '9%' }}>Disc.(%)</th>
                <th style={{ width: '9%' }}>Disc.(₹)</th>
                <th style={{ width: '13%' }}>Sale Value.(₹)</th>
                <th style={{ width: '10%' }}>Tax/GST(%)</th>
                <th style={{ width: '10%' }}>Tax/GST(₹)</th>
                <th style={{ width: '10%' }}>Total cost(₹)</th>
                <th style={{ width: '10%' }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {invProdListItemsData.map((poItems, index) => (
                <tr key={poItems.productId}>
                  <td>{poItems.productName}</td>
                  <td>
                    <input
                      type='number'
                      value={poItems.quantity}
                      style={{ width: '100%' }}
                      className='formtable-input'
                      onChange={(e) => updateQuantityAmount(index, parseInt(e.target.value, 10))}
                      min={1}
                    />
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <input
                      type="number"
                      value={poItems.productUnitPrice}
                      style={{ width: '100%' }}
                      className='formtable-input'
                      onChange={(e) => updateUnitCost(index, parseFloat(e.target.value))}
                      min={0}
                    />
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {poItems.totalProductValue.toLocaleString('en-US',)}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <input
                      type='number'
                      value={poItems.discountPercentage}
                      style={{ width: '100%' }}
                      className='formtable-input'
                      max={100}
                      min={0}
                      onChange={(e) => updateDiscount(index, parseInt(e.target.value, 10))}
                    />
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <input
                      type="number"
                      value={poItems.discountAmount}
                      style={{ width: '100%' }}
                      className='formtable-input'
                      onChange={(e) => handleDiscountAmountChange(index, parseFloat(e.target.value))}
                      min={0}
                    />
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {poItems.totalPurchaseAmount.toLocaleString('en-US',)}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {poItems.taxPercentage}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {poItems.taxAmount.toLocaleString('en-US',)}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {poItems.totalAmount.toLocaleString('en-US',)}
                  </td>
                  <td style={{ textAlign: 'center' }} >
                    <FcEmptyTrash style={{ cursor: 'pointer' }} onClick={() => removeProduct(poItems.productId)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="w-100 row align-items-center justify-content-end mb-1">
        {/* <div className="col-md-8"></div> */}
        {/* <div className="col-md-1">
          <div className="form-group mb-0"> */}
            <label className='end-labels'>Sub Total</label>
          {/* </div>
        </div> */}
        <div className="col-md-2">
          <div className="form-group mb-0">
            <input
              type="text"
              style={{ fontWeight: 'bold', textAlign: 'right', fontSize: "15px" }}
              value={subTotalAmount.toLocaleString('en-US',)}
              className="totals-input form-control"
              placeholder="0.00"
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="w-100 row align-items-center justify-content-end mb-1">
        {/* <div className="col-md-9"></div> */}
        {/* <div className="col-md-1">
          <div className="form-group mb-0"> */}
            <label className='end-labels'>{showTotalTax ? 'Total Tax' : 'Total Discount'}</label>
          {/* </div>
        </div> */}
        <div className="col-md-2">
          <div className="form-group mb-0">
            <input
              type="text"
              style={{ fontWeight: 'bold', textAlign: 'right', fontSize: "15px" }}
              value={(showTotalTax ? taxTotalAmount : discTotalAmount).toLocaleString('en-US',)}
              className="totals-input form-control"
              placeholder="0.00"
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="w-100 row align-items-center justify-content-end mb-1">
        <div className="col-md-4 mr-auto">
          <div className="icheck-primary d-inline">
            <input type="checkbox" id="checkboxPrimary3" />
            <label htmlFor="checkboxPrimary3">Send copy to customer on Email.</label>
          </div>
        </div>
        <div className="col-md-2"></div>
        {/* <div className="col-md-1">
          <div className="form-group mb-0"> */}
            <label className='end-labels'>Grand Total</label>
          {/* </div>
        </div> */}
        <div className="col-md-2">
          <div className="form-group mb-0">
            <input
              type="text"
              style={{ fontWeight: 'bold', textAlign: 'right', fontSize: "15px" }}
              value={grandTotalAmount.toLocaleString('en-US',)}
              className="totals-input form-control"
              placeholder="0.00"
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8"></div>
        <div className="col-md-2">
          <button
            type="button"
            onClick={createSaleInvoice}
            className="btn btn-block btn-success"
          >
            Create Invoice
          </button>
        </div>
        <div className="col-md-2">
          <button type="button" className="btn btn-block btn-danger">Cancel</button>
        </div>
      </div>

      {additionalActions && additionalActions()}
    </div>
  );
};

export default FormTable;
