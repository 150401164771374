import { get, post } from "../APIHandler";

export const getUnpaidInvoicesByCustomerId = async (customerId) => {
    try {
        const response = await get(`/Invoice/Invoice/GetUnpaidInvoiceList?id=${customerId}`);
        console.log("Fetched unpaid invoices:", response);
        return response.data;
    } catch (error) {
        console.error("Error fetching unpaid invoices!", error);
        throw error;
    }
};

export const getInvoiceList = async (filters = {}) => {
    try {
        const response = await post(`/Invoice/GetInvoiceList`, filters || {});
        console.log("Fetched invoices:", response.data);
        return response;
    } catch (error) {
        console.error("Error fetching invoice list!", error);
        throw error;
    }
};

export const createNewInvoice = async (payload) => {
    try {
        const response = await post(`/Invoice/CreateNewInvoice`, payload);
        console.log("Invoice created successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error creating invoice!", error);
        throw error;
    }
};

