import React, { forwardRef } from 'react';

const CustomModal = forwardRef(({
  title,
  searchPlaceholder,
  searchValue,
  onSearchChange,
  columns,
  data,
  onRowClick,
  onRowDoubleClick,
  selectedRow,
  pagination,
  onClose,
  onSelect,
  isOpen,
  id,
}, ref) => {
  return (
    <div className={`modal fade ${isOpen ? 'show' : ''}`} id={id} ref={ref} style={{ display: isOpen ? 'block' : 'none' }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <button type="button" className="close" onClick={onClose} aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              className="form-control mb-3"
              placeholder={searchPlaceholder}
              value={searchValue}
              onChange={onSearchChange}
            />
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  {columns.map((col, index) => (
                    <th key={index}>{col}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <tr
                    key={row.id}
                    onClick={() => onRowClick(row)}
                    onDoubleClick={() => onRowDoubleClick(row)}
                    className={selectedRow === row ? 'table-selected' : ''} // Apply selected class conditionally
                  >
                    {Object.keys(row).map((key) => (
                      key !== 'id' && (
                        <td key={key} style={{ userSelect: 'none' }}>
                          {row[key]}
                        </td>
                      )
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-default" onClick={onClose}>
              Close
            </button>
            {pagination && (
              <div>
                <button className="btn btn-primary" onClick={pagination.onFirstPage} disabled={pagination.currentPage === 1}>
                  First
                </button>
                <button className="btn btn-light" onClick={pagination.onPreviousPage} disabled={pagination.currentPage === 1}>
                  Previous
                </button>
                <span className="mx-2">
                  Page {pagination.currentPage} of {pagination.totalPages}
                </span>
                <button className="btn btn-light" onClick={pagination.onNextPage} disabled={pagination.currentPage === pagination.totalPages}>
                  Next
                </button>
                <button className="btn btn-primary" onClick={pagination.onLastPage} disabled={pagination.currentPage === pagination.totalPages}>
                  Last
                </button>
              </div>
            )}
            <button type="button" className="btn btn-primary" onClick={onSelect}>
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CustomModal;
