import { get, post } from "../APIHandler";

export const getSuppliersList = async (filter = {}) => {
    try {
        const response = await get(`/Supplier/getsupplierlist`, filter);
        console.log("Service Response:", response);
        return response.data;
    } catch (error) {
        console.error("Error fetching supplier data", error);
        throw error;
    }
};

export const deleteSupplierById = async (supplierId) => {
    try {
        const response = await post(`/Supplier/DeleteSupplierById?id=${supplierId}`);
        console.log("Supplier deleted:", response);
        return response;
    } catch (error) {
        console.error("Error deleting supplier", error);
        throw error;
    }
};

export const saveSupplierData = async (formData, isEdit) => {
    const url = isEdit
        ? `/Supplier/UpdateSupplierById`
        : `/Supplier/createnewsupplier`;

    try {
        const response = await post(url, formData);
        console.log("API Response:", response);
        return response.data;
    } catch (error) {
        console.error("Error saving supplier data", error);
        throw error;
    }
};

export const getSupplierDataById = async (id) => {
    try{
        const response = await get(`/Supplier/GetSupplierById?id=${id}`);
        return response.data;
    } catch(error){
        console.log("error while fetching supplierdata", error);
        throw error;
    }
}