import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL } from '../../CommonComp/APIConstants';
import AddCustomer from './AddCustomer';
import { getCustomerById } from '../../service/CustomerService';

function ViewCustomer() {
    const { id } = useParams();
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCustomerDetails = async () => {
            try {
                setLoading(true);
                const response = await getCustomerById(id);
                setCustomer(response);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCustomerDetails();
    }, [id]);

    console.log("this is from view page",customer)

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading customer details</div>;
  return (
    <div>
        <AddCustomer customerDetails={customer} view={true}/>
    </div>
  )
}

export default ViewCustomer