import React, { useState } from 'react';
import { MdEdit, MdDelete, MdOutlineMoreHoriz } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { Modal, Button } from 'react-bootstrap';
import './tablecomponent.css';

const TableComponent = ({ columns, data, onView, onEdit, onDelete, pageSize = 15, idAccessor }) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleRowClick = (rowIndex) => {
    setExpandedRowIndex(prevIndex => prevIndex === rowIndex ? null : rowIndex);
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (onDelete) {
      onDelete(selectedId);
    }
    setShowDeleteModal(false);
    setSelectedId(null);
  };

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = data.slice(indexOfFirstItem, indexOfFirstItem + pageSize);
  const totalPages = Math.ceil(data.length / pageSize);

  return (
    <>
      <div className='table-holder'>
        <table className="table table-hover">
          <thead>
            <tr>
              {columns.map((col) => (
                <th key={col.accessor} className={col.accessor === 'active' ? 'active-column' : ''}>
                  {col.Header}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody style={{fontSize: "14px"}}>
            {currentItems.map((row, rowIndex) => (
              <tr key={row[idAccessor]}>
                {columns.map((col) => {
                  let cellValue = row[col.accessor];
                  return (
                    <td key={col.accessor} className={col.accessor === 'active' ? 'active-column' : ''}>
                      {col.Cell ? col.Cell({ value: cellValue, row }) : cellValue}
                    </td>
                  );
                })}
                <td className='text-center'>
                  <span className="actions" onClick={() => handleRowClick(rowIndex)}>
                    <MdOutlineMoreHoriz size={18} />
                    {expandedRowIndex === rowIndex && (
                      <div className="row-actions">
                        <button onClick={() => onView && onView(row[idAccessor])}>
                          <FaEye size={15} /> View
                        </button>
                        <button onClick={() => onEdit && onEdit(row[idAccessor])} className="edit-option">
                          <MdEdit size={15} /> Edit
                        </button>
                        <button onClick={() => handleDeleteClick(row[idAccessor])} className="edit-option">
                          <MdDelete size={20} /> Delete
                        </button>
                      </div>
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="pagination justify-content-end mt-4">
        <button
          onClick={() => setCurrentPage(1)}
          className={`btn ${currentPage === 1 ? 'btn-primary' : 'btn-light'}`}
          disabled={currentPage === 1}
        >
          First
        </button>
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          className="btn btn-light"
          disabled={currentPage === 1}
        >
          &lt; Previous
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          className="btn btn-light"
          disabled={currentPage === totalPages}
        >
          Next &gt;
        </button>
        <button
          onClick={() => setCurrentPage(totalPages)}
          className={`btn ${currentPage === totalPages ? 'btn-primary' : 'btn-light'}`}
          disabled={currentPage === totalPages}
        >
          Last
        </button>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TableComponent;
