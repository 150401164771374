import React from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SideNav from "./components/SideNav";
import AppRoute from "./components/AppRoute/Index";
import store from "./Store/Store";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import { Toaster } from "sonner";
import AuthProvider from "./context/AuthContext";

function App() {
    const location = useLocation();

    const hideHeader = location.pathname === "/login" || location.pathname === "/register";
    const hideSideNav = location.pathname === "/login" || location.pathname === "/register";

    return (
        <Provider store={store}>
            <AuthProvider>
            <div className="wrapper">
                {!hideHeader && <Header />}
                <AppRoute />
                {!hideHeader && <Footer />}
                {!hideSideNav && <SideNav />}
                <Toaster position="top-right" richColors />
            </div>
            </AuthProvider>
        </Provider>
    );
}

export default App;
