import { fetchCrmCustomersStart, fetchCrmCustomersSuccess, fetchCrmCustomersFailure, addCrmCustomer, updateCrmCustomer, deleteCrmCustomer } from './crmCustomerSlice';
import axios from 'axios';
import { API_URL } from '../../CommonComp/APIConstants';

export const fetchCrmCustomers = () => async (dispatch) => {
    try {
      dispatch(fetchCrmCustomersStart());
      const response = await axios.get(`${API_URL}/CrmCustomer/getcrmcustomers`);
      dispatch(fetchCrmCustomersSuccess(response.data));
    } catch (error) {
      dispatch(fetchCrmCustomersFailure(error.message));
    }
  };

  export const addNewCrmCustomer = (newCrmCustomer) => (dispatch) => {
    dispatch(addCrmCustomer(newCrmCustomer));
  };
  
  export const updateExistingCrmCustomer = (updatedCrmCustomer) => (dispatch) => {
    dispatch(updateCrmCustomer(updatedCrmCustomer));
  };
  
  export const deleteCrmCustomerById = (crmCustomerId) => (dispatch) => {
    dispatch(deleteCrmCustomer(crmCustomerId));
  };