import React from 'react';

function SearchProductModal(props){

    function handleRowClick(products) {
        props.rowClick(products);
    }

    return (
        <div className="modal fade" id="productModal-lg">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Select Product</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table id="example2" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Product Code</th>
                                    <th>Product Name</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.map(products => (
                                    <tr
                                        key={products.productId}
                                        onClick={() => handleRowClick(products)}
                                        style={{ backgroundColor: props.selectedRow === products ? 'lightblue' : 'white' }}
                                    >
                                        <td>{products.productCode}</td>
                                        <td>{products.productName}</td>
                                        <td>{products.ratePerQuantity}</td>
                                        {/* Add more table cells based on your data */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Select</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchProductModal;