import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SearchProductModal from '../../CommonComp/SearchProductModal';
import SearchSupplierModal from '../../CommonComp/SearchSupplierModal';
// import ProductDropdown from '../../CommonComp/ProductDropdown';
import { API_URL } from '../../CommonComp/APIConstants';
import FormTable from '../../CommonComp/TableComponent/Invoice&purchaseTable/FormTable';
import { toast } from 'sonner';

function AddPurchaseOrder() {
    const navigate = useNavigate();

    const [options, setOptions] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [productOption, setProductSelectedOption] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [supplierCodeInput, setSupplierCodeInput] = useState('');
    const [supplierGstInput, setSupplierGstInput] = useState('');

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedProductId, setSelectedProductId] = useState('');

    const [poListItemsData, setPoListItemsData] = useState([]);
    const [discTotalAmount, setDiscTotalAmount] = useState([0]);
    const [subTotalAmount, setSubTotalAmount] = useState([0]);
    const [grandTotalAmount, setGrandTotalAmount] = useState([0]);
    const [taxTotalAmount, setTaxTotalAmount] = useState();

    const calculateTaxTotalAmount = () => {
        return poListItemsData.reduce((total, item) => total + item.taxAmount, 0);
    };

    const PrintLog = () => {
        console.log("selectedOption ====>", selectedOption);
        console.log("supplierGstInput ====>", supplierGstInput);
        console.log("supplierCodeInput ====>", supplierCodeInput);
    }

    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        fetchSupplierData();
        fetchProductsData();
    }, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount

    const fetchSupplierData = async () => {
        try {
            const response = await fetch(`${API_URL}/Supplier/getsupplierlist`); // Replace with your API endpoint
            const data = await response.json();
            //   console.log(data);
            setOptions(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const fetchProductsData = async () => {
        try {
            const response = await fetch(`${API_URL}/Product/getproductlist`); // Replace with your API endpoint
            const data = await response.json();
            console.log(data);
            setProductsData(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    function handleSelectChangeSupplierName(e) {
        //console.log("targetValue", e.target.value);
        setSelectedOption(e.target.value);
        PrintLog();
    };

    function handleSelectChangeProduct(e) {
        console.log("targetValue", e.target.value);
        setProductSelectedOption(e.target.value.productName);
        setSelectedProductId(e.target.value);
        // console.log("in this where selected product id", selectedProductId)
    };

    const handleRowClickSupplierModal = (row) => {
        setSelectedRow(row);
        setSupplierCodeInput(row.supplierCode);
        setSupplierGstInput(row.gstNumber);
        setSelectedOption(row.supplierId);
        PrintLog();
    };

    const handleRowClickProductModal = (row) => {
        setSelectedRow(row);

        setSelectedProductId(row.productId);
        console.log("this is row",row);
    };

    const addProductToList = () => {
        const selectedProduct = productsData.find(option => option.productId === selectedProductId);
        if (!selectedProduct) return;

        const ratePerQuantity = parseFloat(selectedProduct.ratePerQuantity) || 0;
        const taxPercentage = parseFloat(selectedProduct.taxPercentage) || 0;
        const taxAmountPerUnit = ratePerQuantity * (taxPercentage / 100);

        setPoListItemsData(prevItems => {
          const existingProductIndex = prevItems.findIndex(item => item.productId === selectedProductId);

          if (existingProductIndex !== -1) {
            toast.warning("As Product is already present, Quantity will be updated");

            const existingProduct = prevItems[existingProductIndex];
            const newQuantity = existingProduct.quantity + 1;
            const newTotalProductValue = ratePerQuantity * newQuantity;
            const newTotalTaxAmount = taxAmountPerUnit * newQuantity;
            const newTotalAmount = newTotalProductValue + newTotalTaxAmount;

            const updatedProduct = {
              ...existingProduct,
              quantity: newQuantity,
              totalProductValue: newTotalProductValue,
              taxAmount: newTotalTaxAmount,
              totalAmount: newTotalAmount,
              totalPurchaseAmount: newTotalAmount
            };

            const updatedItems = [...prevItems];
            updatedItems[existingProductIndex] = updatedProduct;


            setSubTotalAmount(subTotalAmount + ratePerQuantity );

            setDiscTotalAmount(prevDiscTotal => parseFloat(prevDiscTotal) + (parseFloat(selectedProduct.discountAmount) || 0));
            setGrandTotalAmount(grandTotalAmount + ratePerQuantity + taxAmountPerUnit);

            return updatedItems;
          }

            const newProduct = {
                supplierCode: '',
                productId: selectedProduct.productId,
                productName: selectedProduct.productName,
                productUnitPrice: ratePerQuantity,
                quantity: 1,
                totalProductValue: ratePerQuantity,
                discountPercentage: 0,
                discountAmount: 0,
                totalPurchaseAmount: ratePerQuantity,
                taxPercentage: taxPercentage,
                taxAmount: taxAmountPerUnit,
                totalAmount: ratePerQuantity + taxAmountPerUnit
            };

            setSubTotalAmount(parseFloat(subTotalAmount) + parseFloat(newProduct.totalProductValue));
            setDiscTotalAmount(prevDiscTotal => parseFloat(prevDiscTotal) + newProduct.discountAmount || 0);
            setGrandTotalAmount(parseFloat(grandTotalAmount) + parseFloat(newProduct.totalAmount));

            return [...prevItems, newProduct];
        });
    };





    const updateDiscount = (index, discount) => {


        console.log("Discount AMount ======>",  discount);


        const updatedProducts = [...poListItemsData];
        if (isNaN(discount)) {
            discount = 0;
        }

        updatedProducts[index].discountPercentage = discount;


        let discountAmount = (updatedProducts[index].totalProductValue * (discount/100));
        if (isNaN(discountAmount)) {
            discountAmount = 0;
        }

        updatedProducts[index].discountAmount = discountAmount;

        console.log("totalPurchaseAmount ======>",  updatedProducts[index].totalPurchaseAmount);

        console.log("discountAmount ======>",  discountAmount);

        updatedProducts[index].totalPurchaseAmount = updatedProducts[index].totalProductValue - discountAmount;


        console.log("totalPurchaseAmount ======>",  updatedProducts[index].totalPurchaseAmount);

        let totalTaxAmount = updatedProducts[index].totalPurchaseAmount * (updatedProducts[index].taxPercentage / 100);
        if (isNaN(totalTaxAmount)) {
            totalTaxAmount = 0;
        }

        let totalAmount = updatedProducts[index].totalPurchaseAmount;
        if (updatedProducts[index].taxPercentage > 0) {
            totalAmount = updatedProducts[index].totalPurchaseAmount + (totalTaxAmount);
            if (isNaN(totalAmount)) {
                totalAmount = 0;
            }
        }
        updatedProducts[index].taxAmount = totalTaxAmount
        // updatedProducts[index].totalPurchaseAmount = totalPurchaseAmount;

        updatedProducts[index].totalAmount = totalAmount;

        setPoListItemsData(updatedProducts);
        updateTotalAmount();
    }

    const updateQuantityAmount = (index, quantity) => {
        const updatedProducts = [...poListItemsData];
        updatedProducts[index].quantity = quantity;

        let totalProductValue = updatedProducts[index].quantity * updatedProducts[index].productUnitPrice;
        if (isNaN(totalProductValue)) {
            totalProductValue = 0;
        }

        let totalPurchaseAmount = totalProductValue - updatedProducts[index].discountAmount;
        if (isNaN(totalPurchaseAmount)) {
            totalPurchaseAmount = 0;
        }

        let totalTaxAmount = totalPurchaseAmount * (updatedProducts[index].taxPercentage / 100);
        if (isNaN(totalTaxAmount)) {
            totalTaxAmount = 0;
        }

        let totalAmount = totalPurchaseAmount;
        if (updatedProducts[index].taxPercentage > 0) {
            totalAmount = totalPurchaseAmount + (totalTaxAmount);
            if (isNaN(totalAmount)) {
                totalAmount = 0;
            }
        }

        updatedProducts[index].totalProductValue = totalProductValue;
        updatedProducts[index].totalPurchaseAmount = totalPurchaseAmount;
        updatedProducts[index].taxAmount = totalTaxAmount
        updatedProducts[index].totalPurchaseAmount = totalPurchaseAmount;
        updatedProducts[index].totalAmount = totalAmount;


        setPoListItemsData(updatedProducts);
        updateTotalAmount();
    }

    const updateTotalAmount = () => {
        const grnadTotal = poListItemsData.reduce((total, item) => total + item.totalPurchaseAmount, 0);
        setSubTotalAmount(parseFloat(grnadTotal));
        const grnadDiscount = poListItemsData.reduce((total, item) => total + item.taxAmount, 0);
        setDiscTotalAmount(parseFloat(grnadDiscount));

        let totalAmount = poListItemsData.reduce((total, item) => total + item.totalAmount, 0);
        if (isNaN(totalAmount)) {
            totalAmount = 0;
        }
        setGrandTotalAmount(totalAmount);
        setTaxTotalAmount(calculateTaxTotalAmount()); // Update this line
    };


    const removeProduct = (id) => {
        console.log("ID ====>", id);
        console.log("Before Data ====>", poListItemsData);
        const updatedData = poListItemsData.filter((item) => item.productId !== id);
        console.log("After Data ===>", updatedData);
        setPoListItemsData(updatedData);
        setSubTotalAmount(parseFloat(subTotalAmount) - (parseFloat(updatedData.ratePerQuantity)) || 0);
        setDiscTotalAmount(parseFloat(discTotalAmount) - parseFloat(updatedData.discTotalAmount) || 0);
        console.log("subTotalAmount ===> ", parseFloat(subTotalAmount));
        console.log("discTotalAmount ===> ", parseFloat(discTotalAmount));

        setGrandTotalAmount(parseFloat(grandTotalAmount) - (parseFloat(updatedData.totalAmount)) || 0);
    };

    const createPurchaseOrder = async () => {
        try {

            const payload = {
                SupplierId: selectedOption,
                // SupplierCode: supplierCodeInput,
                PoDate: selectedProductId,
                PurchaseOrderItems: poListItemsData
            }
            const response = await axios.post(`${API_URL}/PurchaseOrder/createpurchaseorder`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status >= 200 && response.status < 300) {
                alert('Purchase Order created successfully');
                navigate("/purchase_order_master");
            } else {
                console.error('Request failed with status', response.status);
            }
            console.log('Data sent successfully', response.data);
        } catch (error) {
            console.error('Failed to send data', error);
        }
    };

    return (
        <div className="content-wrapper">
            <div className="container-fluid">

                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h2>Create Purchase Order</h2>
                    </div>
                </div>

                <div className="card card-default">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Supplier No.</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <div className="input-group-append border border-[#252525] rounded">
                                        <input
                                            type="text"
                                            className="form-control border-0"
                                            id="supplierCodeTxt"
                                            placeholder="Supplier Code"
                                            value={supplierCodeInput}
                                            onChange={setSupplierCodeInput} />
                                        <button className="btn btn-sidebar" data-toggle="modal" data-target="#supplierModal-lg">
                                            <i className="fas fa-search fa-fw" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Purchase Order Date</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <DatePicker
                                        className='w-100'
                                        style={{width: "100%"}}
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="dd/MM/yyyy" // Customize the date format
                                        placeholderText="Select a date"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Supplier Name</label>
                                </div>
                            </div>
                             <div className="col-md-4">
                                <div className="form-group">

                                    <select
                                        className="form-control select2"
                                        style={{ width: '100%' }}
                                        value={selectedOption}
                                        onChange={handleSelectChangeSupplierName}
                                    >
                                        <option value="">Select Supplier</option>
                                        {options.map((option) => (
                                            <option key={option.supplierId} value={option.supplierId}>
                                                {option.supplierName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Supplier GST No.</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="supplierGstTxt"
                                        placeholder="Enter Name"
                                        value={supplierGstInput}
                                        onChange={setSupplierGstInput} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Select Product</label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <div className="input-group-append border border-[#252525] rounded">
                                        <select
                                            className="form-control select2 border-0"
                                            style={{ width: '100%' }}
                                            value={selectedProductId}
                                            onChange={handleSelectChangeProduct}
                                        >
                                            <option value="">Select Product</option>
                                            {productsData.map((product) => (
                                                <option key={product.productId} value={product.productId}>
                                                    {product.productName}
                                                </option>
                                            ))}
                                        </select>
                                        {/* <input
                                            type="text"
                                            className="form-control border-0"
                                            id="productText"
                                            placeholder="Products"
                                            value={selectedProductId.value}
                                            onChange={handleSelectChangeProduct} /> */}
                                        <button className="btn btn-sidebar" data-toggle="modal" data-target="#productModal-lg">
                                            <i className="fas fa-search fa-fw" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1">
                                <button type="button" class="btn btn-block btn-success" onClick={addProductToList}>Add</button>
                            </div>
                        </div>

                        <FormTable
                            invProdListItemsData={poListItemsData}
                            subTotalAmount={subTotalAmount}
                            discTotalAmount={discTotalAmount}
                            grandTotalAmount={grandTotalAmount}
                            taxTotalAmount={taxTotalAmount}
                            updateQuantityAmount={updateQuantityAmount}
                            updateDiscount={updateDiscount}
                            removeProduct={removeProduct}
                            createSaleInvoice={createPurchaseOrder}
                            showTotalTax={true}

                            />

                        </div>
                    </div>
            </div>

            <SearchSupplierModal
            data={options}
            rowClick={(suppliers) => handleRowClickSupplierModal(suppliers)}
            selectedRow={selectedRow}
            />

            <SearchProductModal
            data={productsData}
            rowClick={(products) => handleRowClickProductModal(products)}
            selectedRow={selectedRow}
            />
        </div>
    );
}

export default AddPurchaseOrder;