import React from 'react';

function SearchSupplierModal(props){

    function handleRowClick(suppliers) {
        props.rowClick(suppliers);
    }

    return (
        <div className="modal fade" id="supplierModal-lg">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Select Supplier</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <table id="example2" className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Suppplier Code</th>
                                        <th>Supplier Name</th>
                                        <th>GST</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.map(options => (
                                        <tr
                                            key={options.supplierId}
                                            onClick={() => handleRowClick(options)}
                                            style={{ backgroundColor: props.selectedRow === options ? 'lightblue' : 'white' }}
                                        >
                                            <td>{options.supplierCode}</td>
                                            <td>{options.supplierName}</td>
                                            <td>{options.gstNumber}</td>
                                            {/* Add more table cells based on your data */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Select</button>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default SearchSupplierModal;