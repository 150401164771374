import { get, post } from "../APIHandler";

export const getPaymentList = async (filter = {}) => {
    try {
        const paymentList = await post('/Payment/GetPaymentList', filter);
        console.log("Payment Service Response ========>", paymentList);
        return paymentList;
    } catch (error) {
        console.error('Error fetching payment list', error);
        throw error;
    }
};

export const postPaymentData = async (payment) => {
    try {
        const response = await post('/Payment/AddPayment', payment);
        console.log('Payment successfully posted', response);
        return response;
    } catch (error) {
        console.error('Error posting payment data', error);
        throw error;
    }
};
