import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL } from '../../CommonComp/APIConstants';
import AddProduct from './AddProduct';
import { getProductDataById } from '../../service/ProductService';

function ViewProduct() {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                setLoading(true);
                const response = await getProductDataById(id);
                setProduct(response);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [id]);

    console.log("this is from view page",product)

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading supplier details</div>;
  return (
    <div>
        <AddProduct productDetails={product} view={true}/>
    </div>
  )
}

export default ViewProduct