import { get, post } from "../APIHandler";

export const getCustomerList = async () => {
    try {
        const response = await get(`/Customer/getcustomerlist`);
        return response.data;
    } catch (error) {
        console.error("Error fetching customers!", error);
        throw error;
    }
};

export const createNewCustomer = async (customerData) => {
    try {
        const response = await post(`/Customer/createnewcustomer`, customerData);
        console.log("Customer created successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error creating customer!", error);
        throw error;
    }
};

export const deleteCustomer = async (customerId) => {
    try {
        const response = await post(`/Customer/DeleteCustomer?id=${customerId}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting customer!", error);
        throw error;
    }
};

export const getCustomerById = async (id) => {
    try {
        const response = await get(`/Customer/GetCustomerById?id=${id}`);
        console.log('this is customer detail by id', response.data)
        return response.data;
    } catch (err) {
        console.error("Error Getting Customer Data!", err);
        throw err;
    }
}
