import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { API_URL } from '../../CommonComp/APIConstants';

function AddCustomer({ customerDetails, view, isEdit }) {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        CustomerCode: '',
        CustomerName: '',
        CustomerEmail: '',
        CustomerPhonenumber: '',
        CustomerGSTNumber: '',
        CustomerPANNumber: '',
        CustomerCreditLimit: '',
        Address: {
            AddressLine1: '',
        },
    });

    useEffect(() => {
        if (customerDetails) {
            setFormData({
                CustomerCode: customerDetails.customerCode || '',
                CustomerName: customerDetails.customerName || '',
                CustomerEmail: customerDetails.customerEmail || '',
                CustomerPhonenumber: customerDetails.customerPhonenumber || '',
                CustomerGSTNumber: customerDetails.customerGSTNumber || '',
                CustomerPANNumber: customerDetails.customerPANNumber || '',
                CustomerCreditLimit: customerDetails.customerCreditLimit || '',
                Address: {
                    AddressLine1: customerDetails.address?.addressLine1 || '',
                },
            });
        }
    }, [customerDetails]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name.startsWith('Address.')) {
            const addressField = name.split('.')[1];
            setFormData({
                ...formData,
                Address: {
                    ...formData.Address,
                    [addressField]: value,
                },
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("FormData ===>", formData);

        try {
            let response;
            if (isEdit && customerDetails?.customerId) {
                // Update customer
                response = await axios.put(`${API_URL}/Customer/UpdateCustomer`, {
                    ...formData,
                    customerId: customerDetails.customerId
                });
            } else {
                response = await axios.post(`${API_URL}/Customer/createnewcustomer`, formData);
            }

            console.log('API Response:', response.data);
            navigate("/customer_master");

        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    return (
        <div>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h2>Customer</h2>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/customer_master">Customers</a></li>
                                <li class="breadcrumb-item active">Add Customer</li>
                            </ol>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                    <div className="card card-default">
                        <div className="card-header">
                            <h3 className="card-title">Customer Details</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus" />
                                </button>
                                <button type="button" className="btn btn-tool" data-card-widget="remove">
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                        </div>

                        {/* /.card-header */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Customer Code</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label>Supplier Name</label> */}
                                        <input
                                        type="text"
                                        className="form-control"
                                        name = "CustomerCode"
                                        value={formData.CustomerCode}
                                        onChange={handleChange}
                                        id="custCodeTxt"
                                        placeholder="Enter Code"
                                        disabled={view}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Customer Name</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label>Supplier Name</label> */}
                                        <input
                                        type="text"
                                        className="form-control"
                                        name = "CustomerName"
                                        value={formData.CustomerName}
                                        onChange={handleChange}
                                        id="custNameTxt"
                                        placeholder="Enter Name"
                                        disabled={view} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Mobile No.</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label>Supplier Name</label> */}
                                        <input type="text"
                                        name = "CustomerPhonenumber"
                                        value={formData.CustomerPhonenumber}
                                        onChange={handleChange}
                                        className="form-control" id="custEmailTxt"
                                         placeholder="Enter Name"
                                         disabled={view} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Email</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label>Supplier Name</label> */}
                                        <input type="text"
                                        className="form-control"
                                        name='CustomerEmail'
                                        value={formData.CustomerEmail}
                                        onChange={handleChange}
                                        id="custEmailTxt"
                                        placeholder="Enter Name"
                                        disabled={view} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Phone No.</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label>Supplier Name</label> */}
                                        <input type="text"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder="Enter Name"
                                        disabled={view} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>GST Number</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label>Supplier Name</label> */}
                                        <input type="text"
                                        className="form-control"
                                        name='CustomerGSTNumber'
                                        value={formData.CustomerGSTNumber}
                                        onChange={handleChange}
                                        id="exampleInputEmail1"
                                        placeholder="Enter Name"
                                        disabled={view} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>PAN Number</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label>Supplier Name</label> */}
                                        <input type="text"
                                        className="form-control"
                                        name='CustomerPANNumber'
                                        value={formData.CustomerPANNumber}
                                        onChange={handleChange}
                                        id="custGstNoTxt"
                                        placeholder="Enter Name"
                                        disabled={view} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Credit Limit</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {/* <label>Supplier Name</label> */}
                                        <input type="text"
                                        className="form-control"
                                        name='CustomerCreditLimit'
                                        value={formData.CustomerCreditLimit}
                                        onChange={handleChange}
                                        id="custCreditLimit"
                                        placeholder="Enter Name"
                                        disabled={view} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                <div className="form-group">
                                        <label>Available Limit</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                        <input type="text"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder="Enter Name"
                                        disabled={view} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-md-2">
                            <div className="form-group">
                                        <label>Attachment</label>
                                    </div>
                                    </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="exampleInputFile" disabled={view} />
                                                <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">

                                </div>
                                <div className="col-md-4">

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">

                                </div>
                                <div className="col-md-4">
                                    <div className="icheck-primary d-inline">
                                        <input type="checkbox" id="checkboxPrimary3" disabled={view} />
                                        <label htmlFor="checkboxPrimary3">
                                            Active
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="card card-default">
                        <div className="card-header">
                            <h3 className="card-title">Address Details</h3>
                            <div className="card-tools">
                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                    <i className="fas fa-minus" />
                                </button>
                                <button type="button" className="btn btn-tool" data-card-widget="remove">
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                        </div>

                        {/* /.card-header */}
                        <div className="card-body">

                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Country</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <select className="form-control select2" style={{ width: '100%' }} disabled={view}>
                                            <option selected="selected">Alabama</option>
                                            <option>Alaska</option>
                                            <option>California</option>
                                            <option>Delaware</option>
                                            <option>Tennessee</option>
                                            <option>Texas</option>
                                            <option>Washington</option>
                                            <option>Washington</option>
                                            <option>Washington</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>State</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <select className="form-control select2" style={{ width: '100%' }} disabled={view}>
                                            <option selected="selected">Alabama</option>
                                            <option>Alaska</option>
                                            <option>California</option>
                                            <option>Delaware</option>
                                            <option>Tennessee</option>
                                            <option>Texas</option>
                                            <option>Washington</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>City</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        placeholder="Enter Name"
                                        disabled={view} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Zip/Pin Code</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Name" disabled={view} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Address</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Name" disabled={view} />
                                    </div>
                                </div>
                                {/* <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Zip/Pin Code</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter Name" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-10">
                            {/* <button type="button" class="btn btn-block btn-success">Save</button> */}
                        </div>
                        <div className="col-md-1">
                            <button type="submit" class="btn btn-block btn-success" disabled={view}>{isEdit ?  "Update" : "Save"}</button>
                        </div>
                        <div className="col-md-1">
                            <button type="button" class="btn btn-block btn-danger">Cancel</button>
                        </div>
                    </div>

</form>


                    {/* /.card */}
                </div>




            </div>
        </div>
    );
}

export default AddCustomer;