import { get, post } from "../APIHandler";

export const getProductsList = async (filter = {}) => {
    try {
        const productList = await get('/Product/getproductlist', filter);
        console.log("Service Response ========>", productList);
        return productList.data;
    } catch (error) {
        console.error('Error fetching product data', error);
        throw error;
    }
};

export const addProductData = async (product) => {
    try {
        const response = await post('/Product/createnewproduct', product);
        return response.data;
    } catch (error) {
        console.error('Error posting product data', error);
        throw error;
    }
};

export const deleteProductData = async (productId) => {
    try {
        const response = await post(`/Product/DeleteProduct?id=${productId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting product data', error);
        throw error;
    }
};

export const getProductDataById = async (id) =>{
    try{
        const response = await get(`/Product/GetProductById?id=${id}`);
        return response.data;
    } catch (error) {
        console.error('Error Fetching product data by id', error);
        throw error;
    }
}