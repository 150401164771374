import { get, post } from "../../APIHandler";
export const getInventoryTypes = async () =>{
    try {
        const inventoryTypes = await get('/Setting/GetInventortTypeList');
        console.log("Service Response ========>", inventoryTypes);
        return inventoryTypes;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};

//Post Inventory Type Data
export const AddInventoryType = async (inventoryType) => {
    try {
        const response = await post('/Setting/AddInventoryType', inventoryType);
        return response;
    } catch (error) {
        console.error('Error creating Inventory Type', error);
        throw error;
    }
};