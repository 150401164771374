import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import PaymentTable from "../../CommonComp/invoiceandpaymenttable/PaymentTable";
import PrintInvoice from "./PrintInvoice";
import { API_URL } from "../../CommonComp/APIConstants";
import "./invoicespage.css";
import { FormControl } from "react-bootstrap";
import CustomerDropdown from "../../CommonComp/CustomerDropdown";
import { getCustomerList } from "../../service/CustomerService";
import { getInvoiceList } from "../../service/InvoiceService";

function Invoices() {
    const navigate = useNavigate();
    const componentRef = useRef();
    const [invoices, setInvoices] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [expandedActionRowIndex, setExpandedActionRowIndex] = useState(null);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [isPrintReady, setIsPrintReady] = useState(false);
    const [downloadRequested, setDownloadRequested] = useState(false);
    const [dateRange, setDateRange] = useState("thisMonth");
    const [fromDate, setFromDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0]
      );

    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    // const [toDate, setToDate] = useState("");
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");

    const invoicesPerPage = 12;

    const triggerPrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => setSelectedInvoiceId(null),
    });

    const formatAmount = (amount) => {
        return typeof amount === 'number'
            ? amount.toLocaleString('en-IN')
            : amount;
    };

    const getPaymentStatus = (status) => {
        switch (status) {
            case 0: return { text: "Pending", color: "red" };
            case 1: return { text: "Partial Paid", color: "orange" };
            case 2: return { text: "Fully Paid", color: "green" };
            case 3: return { text: "Settled", color: "blue" };
            default: return { text: "Unknown", color: "black" };
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchInvoices();
    }, []);

    const fetchInvoices = async (filters) => {
        setLoading(true);
        try {
            const response = await getInvoiceList(filters); // Call the service here
            setInvoices(response);
        } catch (error) {
            console.error("There was an error fetching the invoice data!", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        const initialFilters = {
            searchTerm: "",
            customerId: null,
            startdate: fromDate,
            enddate: toDate,
            paymentStatus: selectedPaymentStatus !== "" ? parseInt(selectedPaymentStatus) : undefined,
        };
        fetchInvoices(initialFilters);
    }, []);


    useEffect(() => {
        const filters = {
            searchTerm: "",
            customerId: null,
            startdate: fromDate,
            enddate: toDate,
            paymentStatus: selectedPaymentStatus !== "" ? parseInt(selectedPaymentStatus) : undefined,
        };
        fetchInvoices(filters);
    }, []);


    const handleSearch = () => {
        const filters = {
            searchTerm: searchQuery,
            customerId: selectedCustomer || null,
            startdate: fromDate,
            enddate: toDate,
            paymentStatus: selectedPaymentStatus !== "" ? parseInt(selectedPaymentStatus) : undefined,
        };
        fetchInvoices(filters);
    };



    const filteredInvoices = useMemo(() => {
        return invoices.filter(invoice => {
            const invoiceDate = new Date(invoice.invoiceDate);
            const matchesDate = fromDate ? invoiceDate >= new Date(fromDate) : true;
            const matchesSearch = searchQuery
                ? invoice.customerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  invoice.invoiceNumber?.toLowerCase().includes(searchQuery.toLowerCase())
                : true;
            const matchesPaymentStatus =
                selectedPaymentStatus === "" || invoice.paymentStatus === parseInt(selectedPaymentStatus);
            return matchesDate && matchesSearch && matchesPaymentStatus;
        });
    }, [invoices, fromDate, toDate, searchQuery]);





    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = useMemo(() => filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice), [filteredInvoices, currentPage]);


    const totalPages = Math.ceil(filteredInvoices.length / invoicesPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleRowClick = (index) => {
        setExpandedRowIndex(expandedRowIndex === index ? null : index);
    };
    const handleActionRowClick = (index) => {
        setExpandedActionRowIndex(expandedActionRowIndex === index ? null : index);
    };

    const handlePrint = (invoiceId) => {
        setSelectedInvoiceId(invoiceId);
        triggerPrint();
        setIsPrintReady(false);
    };

    const handleDownload = (invoiceId) => {
        setSelectedInvoiceId(invoiceId);
        setDownloadRequested(true);
        setIsPrintReady(false);
    };

    useEffect(() => {
        getCustomerList()
            .then(customerData => setCustomers(customerData))
            .catch(error => console.error("Error fetching customers!", error));
    }, []);

    const handleDateRangeChange = (e) => {
        const selectedRange = e.target.value;
        setDateRange(selectedRange);

        const today = new Date();
        let from, to;

        switch (selectedRange) {
          case "today":
            from = to = today.toISOString().split('T')[0];
            break;
          case "thisWeek":
            const startOfWeek = new Date(today);
            from = new Date(startOfWeek.setDate(today.getDate() - today.getDay())).toISOString().split('T')[0];
            to = today.toISOString().split('T')[0];
            break;
          case "thisMonth":
            from = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
            to = today.toISOString().split('T')[0];
            break;
          case "lastMonth":
            const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            from = startOfLastMonth.toISOString().split('T')[0];
            to = new Date(today.getFullYear(), today.getMonth(), 0).toISOString().split('T')[0];
            break;
          case "thisYear":
            from = new Date(today.getFullYear(), 0, 1).toISOString().split('T')[0];
            to = today.toISOString().split('T')[0];
            break;
          case "lastYear":
            from = new Date(today.getFullYear() - 1, 0, 1).toISOString().split('T')[0];
            to = new Date(today.getFullYear() - 1, 11, 31).toISOString().split('T')[0];
            break;
          case "custom":
            break;
          default:
            break;
        }

        if (selectedRange !== "custom") {
          setFromDate(from);
          setToDate(to);
        }
      };



    const handleCustomerChange = (customerId) => {
        setSelectedCustomer(customerId);

    };




    useEffect(() => {
        if (selectedInvoiceId && isPrintReady) {
            if (downloadRequested) {
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: [650, 850],
                });

                const htmlContent = componentRef.current.innerHTML;

                pdf.html(htmlContent, {
                    callback: function (pdf) {
                        pdf.save(`invoice_${selectedInvoiceId}.pdf`);
                        setSelectedInvoiceId(null);
                        setDownloadRequested(false);
                        setIsPrintReady(false);
                    },
                    x: 10,
                    y: 10,
                    html2canvas: { scale: 1 },
                    margin: [10, 10, 10, 10],
                });
            } else {
                triggerPrint();
            }
        }
    }, [isPrintReady, selectedInvoiceId, downloadRequested, triggerPrint]);



    const handlePrintReady = () => {
        setIsPrintReady(true);
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h2>Invoices</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body" style={{ minHeight: "530px", position: "relative" }}>
                                    <div className="row mb-2 d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between mx-2">


                                    <div className="mr-1">
                                        <label htmlFor="dateRange">Date Range</label>
                                        <select
                                            id="dateRange"
                                            value={dateRange}
                                            onChange={handleDateRangeChange}
                                            className="range-dropdown form-control w-auto"
                                        >
                                            <option value="today">Today</option>
                                            <option value="thisWeek">This Week</option>
                                            <option value="thisMonth">This Month</option>
                                            <option value="lastMonth">Last Month</option>
                                            <option value="thisYear">This Year</option>
                                            <option value="lastYear">Last Year</option>
                                            <option value="custom">Custom</option>
                                        </select>
                                    </div>

                                    <div className="mr-1">
                                        <label htmlFor="fromDate">From Date</label>
                                        <FormControl
                                            id="fromDate"
                                            type="date"
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e.target.value)}
                                            className="date-input payment-input"
                                            disabled={dateRange !== "custom"}
                                        />
                                    </div>

                                    <div className="mr-1">
                                        <label htmlFor="toDate">To Date</label>
                                        <FormControl
                                            id="toDate"
                                            type="date"
                                            value={toDate}
                                            onChange={(e) => setToDate(e.target.value)}
                                            className="date-input payment-input "
                                            disabled={dateRange !== "custom"}
                                        />
                                    </div>

                                    <div className="mr-1">
                                    <CustomerDropdown
                                            customers={customers}
                                            onSelectCustomer={handleCustomerChange}
                                        />
                                        </div>

                                        <div className="mr-1 d-flex flex-column ">
                                           <label htmlFor="search">Search</label>
                                            <input
                                                type="text"
                                                id="search"
                                                placeholder="Search by customer name or invoice number..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                className="date-input payment-input"
                                                style={{ maxWidth: '300px', display: 'inline-block' }}
                                            />
                                        </div>

                                        <div className="mr-1">
                                                <label htmlFor="paymentStatus">Payment Status</label>
                                                <select
                                                    id="paymentStatus"
                                                    value={selectedPaymentStatus}
                                                    onChange={(e) => setSelectedPaymentStatus(e.target.value)}
                                                    className="range-dropdown form-control w-auto"
                                                >
                                                    <option value="">All</option>
                                                    <option value="0">Pending</option>
                                                    <option value="1">Partial Paid</option>
                                                    <option value="2">Fully Paid</option>
                                                    <option value="3">Settled</option>
                                                </select>
                                            </div>

                                        <div className="col-md-1">

                                            <button style={{ marginBottom: '-20px' }} type="button" onClick={handleSearch}  className="btn btn-block btn-primary">Go</button>
                                        </div>

                                        </div>



                                        <div className="col-md-1">
                                            <button style={{ marginBottom: '10px' }} type="button" onClick={() => navigate("/create_invoice")} className="btn btn-block btn-success">Add</button>
                                        </div>
                                    </div>

                                    {loading ? (
                                        <div className="loading-spinner">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <PaymentTable
                                            currentInvoices={currentInvoices}
                                            indexOfFirstInvoice={indexOfFirstInvoice}
                                            indexOfLastInvoice={indexOfLastInvoice}
                                            filteredInvoices={filteredInvoices}
                                            expandedRowIndex={expandedRowIndex}
                                            setExpandedRowIndex={setExpandedRowIndex}
                                            expandedActionRowIndex={expandedActionRowIndex}
                                            setExpandedActionRowIndex={setExpandedActionRowIndex}
                                            setCurrentPage={setCurrentPage}
                                            totalPages={totalPages}
                                            currentPage={currentPage}
                                            formatAmount={formatAmount}
                                            getPaymentStatus={getPaymentStatus}
                                            onPrint={handlePrint}
                                            onDownload={handleDownload}
                                            selectedInvoiceId={selectedInvoiceId}
                                            componentRef={componentRef}
                                            handlePrintReady={handlePrintReady}
                                            isThisInvoice={true}
                                            callapsableTable={true}
                                        />


                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {selectedInvoiceId && (
                <div style={{ display: 'none' }}>
                    <div ref={componentRef}>
                        <PrintInvoice invoiceId={selectedInvoiceId} onReady={handlePrintReady}  />
                    </div>
                </div>
            )}
        </>
    );
}

export default Invoices;
