import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { API_URL } from '../../CommonComp/APIConstants';
import { toast } from 'sonner';
import { saveSupplierData } from '../../service/SupplierService';

function AddSupplier({ supplierDetail, view, isEdit }) {
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        SupplierCode: '',
        SupplierName: '',
        GSTNumber: '',
        PANNumber: '',
        Email: '',
        Phonenumber: '',
        SupplierContactPerson: '',
        AddressId: null,
        SupplierAddressLine1: '',
        SupplierAddressLine2: '',
        SupplierAddressState: '',
        SupplierAddressCity: '',
        SupplierAddressZipcode: '',
    });

    useEffect(() => {
        if (supplierDetail) {
            setFormData({
                SupplierCode: supplierDetail.supplierCode || '',
                SupplierName: supplierDetail.supplierName || '',
                GSTNumber: supplierDetail.gstNumber || '',
                PANNumber: supplierDetail.panNumber || '',
                Email: supplierDetail.email || '',
                Phonenumber: supplierDetail.phonenumber || '',
                SupplierContactPerson: supplierDetail.supplierContactPerson || '',
                AddressId: supplierDetail.addressId || null,
                SupplierAddressLine1: supplierDetail.supplierAddressLine1 || '',
                SupplierAddressLine2: supplierDetail.supplierAddressLine2 || '',
                SupplierAddressState: supplierDetail.supplierAddressState || '',
                SupplierAddressCity: supplierDetail.supplierAddressCity || '',
                SupplierAddressZipcode: supplierDetail.supplierAddressZipcode || '',
            });
        }
    }, [supplierDetail]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.SupplierName) {
            newErrors.SupplierName = 'Supplier Name is required';
        }
        if (!formData.SupplierCode) {
            newErrors.SupplierCode = 'Supplier Code is required';
        }
        if (!formData.Phonenumber) {
            newErrors.Phonenumber = 'Phone number is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error("Please fill in all required fields");
            return;
        }

        if (isEdit && supplierDetail && supplierDetail.supplierId) {
            formData.supplierId = supplierDetail.supplierId;
        }

        try {
            const response = await saveSupplierData(formData, isEdit);
            console.log("API Response:", response);

            const successMessage = isEdit ? "Supplier Updated Successfully" : "Supplier Created Successfully";
            toast.success(successMessage);
            navigate("/supplier_master");
        } catch (error) {
            console.error("Error posting data:", error);
            toast.error("Failed to save supplier data.");
        }
    };


    return (
        <div>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div class="row mb-2" style={{height: "45px"}}>
                        <div class="col-sm-6">
                            <h2>Supplier</h2>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/supplier_master">Suppliers</a></li>
                                <li class="breadcrumb-item active">Add Supplier</li>
                            </ol>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} style={{fontSize: "14px", whiteSpace: "nowrap"}}>
                        <div className="card card-default">
                            <div className="card-header">
                                <h3 className="card-title">Supplier Details</h3>

                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus" />
                                    </button>
                                    <button type="button" className="btn btn-tool" data-card-widget="remove">
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                            </div>

                            <div className="card-body" style={{height: "65vh", position: "relative"}}>

                                <div className="row" style={{display: "flex", alignItems: "center"}}>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>Supplier Name</label>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            {/* <label>Supplier Name</label> */}
                                            <input type="text"
                                                style={{padding: ".15rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.SupplierName ? 'is-invalid' : ''}`}
                                                name="SupplierName"
                                                value={formData.SupplierName}
                                                onChange={handleChange}
                                                id="exampleInputEmail1"
                                                placeholder="Enter Supplier Name"
                                                readOnly={view}
                                                />

                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>Supplier Code</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="text"
                                                name="SupplierCode"
                                                value={formData.SupplierCode}
                                                onChange={handleChange}
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.SupplierCode ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                placeholder="Enter Supplier Code"
                                                readOnly={view}
                                                 />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{display: "flex", alignItems: "center"}}>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>Phonenumber</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="number"
                                                name="Phonenumber"
                                                value={formData.Phonenumber}
                                                onChange={handleChange}
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.Phonenumber ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                placeholder="Enter Supplier Phonenumber"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>Supplier Email</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="text"
                                                name="Email"
                                                value={formData.Email}
                                                onChange={handleChange}
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.Email ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                placeholder="Enter Supplier Email"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{display: "flex", alignItems: "center"}}>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>GST Number</label>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            {/* <label>Supplier Name</label> */}
                                            <input type="text"
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.GSTNumber ? 'is-invalid' : ''}`}
                                                name="GSTNumber"
                                                value={formData.GSTNumber}
                                                onChange={handleChange}
                                                id="exampleInputEmail1"
                                                placeholder="Enter GST Number"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>PAN Number</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="text"
                                                name="PANNumber"
                                                value={formData.PANNumber}
                                                onChange={handleChange}
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.PANNumber ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                placeholder="Enter PAN Number"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{display: "flex", alignItems: "center"}}>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>Supplier Contact</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="text"
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.SupplierContactPerson ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                name="SupplierContactPerson"
                                                value={formData.SupplierContactPerson}
                                                onChange={handleChange}
                                                placeholder="Enter Supplier Contact Person"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{display: "flex", alignItems: "center"}}>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>Address Line 1</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="text"
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.SupplierAddressLine1 ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                name="SupplierAddressLine1"
                                                value={formData.SupplierAddressLine1}
                                                onChange={handleChange}
                                                placeholder="Enter Supplier Address Line 1"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>Address Line 2</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="text"
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.SupplierAddressLine2 ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                name="SupplierAddressLine2"
                                                value={formData.SupplierAddressLine2}
                                                onChange={handleChange}
                                                placeholder="Enter Supplier Address Line 2"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{display: "flex", alignItems: "center"}}>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>City</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="text"
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.SupplierAddressCity ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                name="SupplierAddressCity"
                                                value={formData.SupplierAddressCity}
                                                onChange={handleChange}
                                                placeholder="Enter Supplier City"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>State</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="text"
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.SupplierAddressState ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                name="SupplierAddressState"
                                                value={formData.SupplierAddressState}
                                                onChange={handleChange}
                                                placeholder="Enter Supplier State"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{display: "flex", alignItems: "center"}}>
                                    <div className="col-md-2">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <label>Zip Code</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{marginBottom: "0.3rem"}} >
                                            <input type="number"
                                                style={{padding: ".375rem .75rem", height: "calc(1.9rem + 2px)"}}
                                                className={`form-control ${errors.SupplierAddressZipcode ? 'is-invalid' : ''}`}
                                                id="exampleInputEmail1"
                                                name="SupplierAddressZipcode"
                                                value={formData.SupplierAddressZipcode}
                                                onChange={handleChange}
                                                placeholder="Enter Supplier Zip Code"
                                                readOnly={view} />
                                        </div>
                                    </div>
                                </div>



                                 <div className="row" style={{width: "100%",display: "flex", alignItems: "center", position: "absolute", bottom: "1%"}}>
                                    <div className="col-md-10">
                                        {/* <button type="button" class="btn btn-block btn-success">Save</button> */}
                                    </div>

                                    <div className="col-md-1">
                                        <button disabled={view} type="submit" class="btn btn-block btn-success">Save</button>
                                    </div>


                                    <div className="col-md-1">
                                        <button type="button" class="btn btn-block btn-danger text-nowrap" onClick={() => navigate(-1)}>Cancel</button>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddSupplier;