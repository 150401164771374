import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AppConfig from '../../config';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCrmCustomers } from '../../Store/Slices/crmCustomerAction';
import { BsPencilSquare } from 'react-icons/bs';


function CrmCustomers() {
    
    const navigate = useNavigate(); 
    const dispatch = useDispatch();


    const { crmCustomers, loading, error } = useSelector((state) => state.crmCustomers);


    useEffect(() => {
        dispatch(fetchCrmCustomers());
      }, [dispatch]);

    const addCrmCustomer = (e) =>{
        e.preventDefault();
        navigate("/crm_add_customer");
    }
    
    const updateCrmCustomer = (e) =>{
        e.preventDefault();

        navigate("/crm_edit_customer/22B0AE44-DFE4-451E-93DB-244C8C472131");
    }

    const EditCrmCustomer = (id) =>{
        navigate(`/crm_edit_customer/${id}`);
    }

    return (
        <div>
            <div className="content-wrapper">
                <div className="container-fluid">

                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h2>Customers</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-10">
                                        </div>
                                        <div className="col-md-1">
                                            <button
                                                style={{ marginBottom: '10px' }}
                                                type="button"
                                                onClick={addCrmCustomer}
                                                className="btn btn-block btn-success">Add
                                            </button>
                                        </div>
                                        <div className="col-md-1">
                                            <button
                                                style={{ marginBottom: '10px' }}
                                                type="button"
                                                onClick={updateCrmCustomer}
                                                className="btn btn-block btn-success">Update
                                            </button>
                                        </div>
                                    </div>

                                    <table id="crmCustomertableId" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Name</th>
                                                <th>Phonenumber</th>
                                                <th>Email</th>
                                                <th>Website</th>
                                                <th>Reference</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {crmCustomers.map((crmCustomer, index) => (
                                                <tr key={crmCustomer.id}>
                                                    <td>{crmCustomer.companyName}</td>
                                                    <td>{crmCustomer.firstName} {crmCustomer.lastName}</td>
                                                    <td>{crmCustomer.phonenumber1}</td>
                                                    <td>{crmCustomer.email}</td>
                                                    <td>{crmCustomer.website}</td>
                                                    <td>{crmCustomer.referenceName}</td>
                                                    <td>
                                                        <BsPencilSquare  onClick={() => EditCrmCustomer(crmCustomer.id)}/>
                                                        {/* <FcViewDetails onClick={() => ViewPurchaseOrder(poItems.purchaseMasterOrderId)} /> */}
                                                        {/* <FcEmptyTrash /> */}
                                                        {/* <FcExpand /> */}
                                                    </td>
                                                </tr>
                                            ))}


                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default CrmCustomers;