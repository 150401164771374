import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../CommonComp/APIConstants";

const initialState = {
  crmCustomers: [],
  loading: false,
  error: null,
};

export const addCrmCustomer = createAsyncThunk(
  'addCrmCustomer',
  async (customerData, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/CrmCustomer/createnewcrmcustomer`, customerData);
      return response.data; // assuming the response contains the saved customer data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateCrmCustomer = createAsyncThunk(
  'updateCrmCustomer',
  async (customerData, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/CrmCustomer/updatecrmcustomer`, customerData);
      return response.data; // assuming the response contains the saved customer data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const crmCustomerSlice = createSlice({
  name: "crmCustomer",
  initialState: initialState,
  reducers: {
    fetchCrmCustomersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCrmCustomersSuccess(state, action) {
      state.loading = false;
      state.crmCustomers = action.payload;
    },
    fetchCrmCustomersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteCrmCustomer: (state, action) => {
      state.crmCustomers = state.crmCustomers.filter(customer => customer.id !== action.payload);
    },
    // updateCrmCustomer: (state, action) => {
    //   const { id, newData } = action.payload;
    //   const customerToUpdate = state.crmCustomers.find(customer => customer.id === id);
    //   if (customerToUpdate) {
    //     Object.assign(customerToUpdate, newData);
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCrmCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCrmCustomer.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addCrmCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateCrmCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCrmCustomer.fulfilled, (state, action) => {
        const { id, newData } = action.payload;
        const customerToUpdate = state.crmCustomers.find(customer => customer.id === id);
        if (customerToUpdate) {
          Object.assign(customerToUpdate, newData);
        }

        state.loading = false;
      })
      .addCase(updateCrmCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
})

export const {
  fetchCrmCustomersStart,
  fetchCrmCustomersSuccess,
  fetchCrmCustomersFailure,
  //addCrmCustomer,
  deleteCrmCustomer,
 // updateCrmCustomer
} = crmCustomerSlice.actions;

export default crmCustomerSlice.reducer;