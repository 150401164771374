import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AppConfig from '../../config';
import { API_URL } from '../../CommonComp/APIConstants';
import { useNavigate } from "react-router-dom";
import { userColumns } from "../../CommonComp/TableComponent/EntityTables/UserTblColumns";
import TableComponent from "../../CommonComp/TableComponent/TableComponent";

function UserList() {

    const navigate = useNavigate();

    const [usersData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const addUser = (e) => {
        e.preventDefault();
        navigate("/create_user");
    } 
    
    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetchUsersData();
    }, []);

    const fetchUsersData = async () => {
        console.log(`${AppConfig.apiUrl}/api/User/GetUsers`);
        try {
            const response = await axios.get(`${API_URL}/User/GetUsers`);
            setData(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h2>Users</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-11">
                                        </div>
                                        <div className="col-md-1">
                                            <button  style={{ marginBottom: '10px' }}
                                            type="button" onClick={addUser} class="btn btn-block btn-success">Add</button>
                                        </div>
                                    </div>
                                    <TableComponent columns={userColumns} data={usersData}></TableComponent>
                                </div>  {/* /.card-body */}
                            </div> {/* /.card */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserList;