import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import ViewPurchaseOrder from "./ViewPurchaseOrder";
import { FcViewDetails, FcEmptyTrash  } from "react-icons/fc";
import { BsPencilSquare } from "react-icons/bs";
import { FcExpand } from "react-icons/fc";
import { API_URL } from "../../CommonComp/APIConstants";

function PurchaseOrderList() {

    const [purchaseOrderListData, setPurchaseOrderListData] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetchPurchaseOrderListData();
    }, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount

    const fetchPurchaseOrderListData = async () => {
        try {
            const response = await fetch(`${API_URL}/PurchaseOrder/getpurchaseorders`); // Replace with your API endpoint
            const data = await response.json();
            console.log(data);
            setPurchaseOrderListData(data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const createNewPurchaseOrder = (e) => {
        e.preventDefault();
        navigate("/create_purchase_order");
    }


    const ViewPurchaseOrder = (id) => {
        console.log("ID TO SEND ====>", id);
        navigate(`/view_purchase_order/${id}`);
    };

    return (
        <div>
            <div className="content-wrapper">

                <div className="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h2>Purchase Orders</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                {/* <div className="card-header">
                                    <h3 className="card-title">Purchase Order Details</h3>
                                </div> */}
                                {/* /.card-header */}
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-11">
                                        </div>
                                        <div className="col-md-1">
                                            <button
                                                type="button"
                                                style={{ marginBottom: '10px' }}
                                                data-tip="Create New Purchase Order."
                                                onClick={createNewPurchaseOrder}
                                                class="btn btn-block btn-success">Add</button>
                                        </div>
                                    </div>


                                    <table id="example2" className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>PO Number</th>
                                                <th>Order Date</th>
                                                <th>Supplier</th>
                                                <th>Amount</th>
                                                <th>Tax Amount</th>
                                                <th>Total</th>
                                                <th>Order Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {purchaseOrderListData.map((poItems, index) => (
                                                <tr
                                                    key={poItems.purchaseMasterOrderId}
                                                >
                                                    <td>{poItems.orderNumber}</td>
                                                    <td>{new Date(poItems.orderDate).toLocaleDateString()}</td>
                                                    <td>{poItems.supplierName}</td>
                                                    <td style={{ textAlign: 'right' }}>{poItems.subTotalAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                    <td style={{ textAlign: 'right' }}>{poItems.totalTaxAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>

                                                    <td style={{ textAlign: 'right' }}>{poItems.grandTotalAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>

                                                    <td style={{ textAlign: 'center' }}>Created</td>

                                                    <td>
                                                        <BsPencilSquare />
                                                        <FcViewDetails onClick={() => ViewPurchaseOrder(poItems.purchaseMasterOrderId)} />
                                                        <FcEmptyTrash />
                                                        <FcExpand />
                                                    </td>

                                                    {/* <td style={{ textAlign: 'center' }}>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-info">Action</button>
                                                            <button type="button" className="btn btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                                                <span className="sr-only">Toggle Dropdown</span>
                                                            </button>
                                                            <div className="dropdown-menu" role="menu">
                                                                <a className="dropdown-item" onClick={() => ViewPurchaseOrder(poItems.purchaseMasterOrderId)}>View</a>
                                                                <a className="dropdown-item" href="#">Generate GRN</a>
                                                                <a className="dropdown-item" href="#">Edit</a>
                                                                <a className="dropdown-item" href="#">Make Payment</a>
                                                            </div>
                                                        </div>


                                                    </td> */}
                                                    {/* <td>
                                                    <input
                                                        type='number'
                                                        value={poItems.prodQty}
                                                        style={{ width: '100%' }}
                                                        onChange={(e) => updateAmount(index, parseInt(e.target.value, 10))} />

                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.prodUnitCost.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.prodPurchaseAmt.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.prodDiscount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.prodTax.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                <td style={{ textAlign: 'right' }}>
                                                    {poItems.prodTotalAmt.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                <td></td> */}
                                                </tr>
                                            ))}

                                        </tbody>
                                        {/* <tfoot>
                                            <tr>
                                                <th>Rendering engine</th>
                                                <th>Browser</th>
                                                <th>Platform(s)</th>
                                                <th>Engine version</th>
                                                <th>CSS grade</th>
                                            </tr>
                                        </tfoot> */}
                                    </table>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default PurchaseOrderList;