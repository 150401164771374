import { motion } from 'framer-motion';
import './spinner.css';

const spinnerVariants = {
  spin: {
    rotate: 360,
    transition: {
      repeat: Infinity,
      duration: 1,
      ease: 'linear'
    }
  }
};

function Spinner() {
  return (
    <motion.div
      className="spinner"
      variants={spinnerVariants}
      animate="spin"
    />
  );
}

export default Spinner;