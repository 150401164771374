import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from "../../CommonComp/APIConstants";

function ViewPurchaseOrder() {
    const { purchaseOrderId } = useParams();
    const [invoiceData, setInvoiceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInvoiceData = async () => {
            try {
                const response = await axios.get(`${API_URL}/PurchaseOrder/getpurchaseorderbyid?purchaseOrderId=${purchaseOrderId}`);
                if (response.status !== 200) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = response.data;
                setInvoiceData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchInvoiceData();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div>
        <div className="content-wrapper">
            <div className="container-fluid">

                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h2>Purchase Order</h2>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="/purchase_order_master">Purchase Orders</a></li>
                            <li class="breadcrumb-item active">View Purchase Order</li>
                        </ol>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">

                        {/* Main content */}
                        <div className="invoice p-3 mb-3">
                            {/* title row */}
                            <div className="row">
                                <div className="col-12">
                                    <h4>
                                        <i className="fas fa-globe" /> HSL Corporation.
                                        <small className="float-right">Date: 08/01/2024</small>
                                    </h4>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* info row */}
                            <div className="row invoice-info">
                                <div className="col-sm-4 invoice-col">
                                    From,
                                    <address>
                                        {/* <strong>{apiData.supplierName}</strong><br /> */}
                                        HSL House, C-65. STICE, Musalgoan MIDC, <br />
                                        Sinnar, Nashik-422112, <br />
                                        Maharashtra, India<br />
                                        Phone: (804) 123-5432<br />
                                        Email: info@almasaeedstudio.com<br />
                                        GST 27AGJPL0925G1ZH
                                    </address>
                                </div>
                                {/* /.col */}
                                <div className="col-sm-4 invoice-col">
                                    To,
                                    <address>
                                        <strong>{invoiceData.supplierName}</strong><br />
                                        795 Folsom Ave, Suite 600<br />
                                        Chennai, India<br />
                                        Phone: (555) 539-1037<br />
                                        Email: mah.sales@mahindra.com
                                    </address>
                                </div>
                                {/* /.col */}
                                <div className="col-sm-4 invoice-col">
                                    {/* <b>Invoice #007612</b><br /> */}
                                    <br />
                                    <b>Order ID:</b> {invoiceData.orderNumber}<br />
                                    {/* <b>Payment Due:</b> 2/22/2014<br /> */}
                                    {/* <b>Account:</b> 968-34567 */}
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                            {/* Table row */}
                            <div className="row">
                                <div className="col-12 table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '30%' }}>Product Name</th>
                                                <th style={{ width: '8%' }}>Qty</th>
                                                <th style={{ width: '10%' }}>Unit cost</th>
                                                <th style={{ width: '20%' }}>Product Value(₹)</th>
                                                <th style={{ width: '10%' }}>Disc.(%)</th>
                                                <th style={{ width: '9%' }}>Disc.(₹)</th>
                                                <th style={{ width: '15%' }}>Purchase Amt.(₹)</th>
                                                <th style={{ width: '10%' }}>Tax/GST(%)</th>
                                                <th style={{ width: '10%' }}>Tax/GST(₹)</th>
                                                <th style={{ width: '10%' }}>Total cost(₹)</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {invoiceData.purchaseOrderDetails.map((products, index) => (
                                                <tr
                                                    key={products.productId}
                                                >
                                                    <td>{products.productName}</td>
                                                    <td>{products.quantity}</td>
                                                    <td>{products.productUnitPrice.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                    <td>{products.totalProductValue.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                    <td>{products.discountPercentage}%</td>
                                                    <td>{products.discountAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                    <td>{products.totalPurchaseAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                    <td>{products.taxPercentage}%</td>
                                                    <td>{products.taxAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                    <td>{products.totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                    {/* <td>{products.totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td> */}
                                                    {/* <td>{products.active}</td> */}

                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                            <div className="row">
                                {/* accepted payments column */}
                                <div className="col-6">
                                    {/* <p className="lead">Payment Methods:</p>
                                <img src="../../dist/img/credit/visa.png" alt="Visa" />
                                <img src="../../dist/img/credit/mastercard.png" alt="Mastercard" />
                                <img src="../../dist/img/credit/american-express.png" alt="American Express" />
                                <img src="../../dist/img/credit/paypal2.png" alt="Paypal" />
                                <p className="text-muted well well-sm shadow-none" style={{ marginTop: 10 }}>
                                    Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles, weebly ning heekya handango imeem
                                    plugg
                                    dopplr jibjab, movity jajah plickers sifteo edmodo ifttt zimbra.
                                </p> */}
                                </div>
                                {/* /.col */}
                                <div className="col-6">


                                    {/* <p className="lead">Amount Due 2/22/2014</p> */}
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>Subtotal:</th>
                                                    <td style={{ textAlign: 'right' }}>{invoiceData.purchaseOrderDetails.reduce((total, item) => total + item.totalProductValue, 0).toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>Discount:</th>
                                                    <td style={{ textAlign: 'right' }}>{invoiceData.purchaseOrderDetails.reduce((total, item) => total + item.discountAmount, 0).toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>Tax Amount</th>
                                                    <td style={{ textAlign: 'right' }}>{invoiceData.purchaseOrderDetails.reduce((total, item) => total + item.taxAmount, 0).toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>Total:</th>
                                                    <td style={{ textAlign: 'right' }}>{invoiceData.purchaseOrderDetails.reduce((total, item) => total + item.totalAmount, 0).toLocaleString('en-US', { style: 'currency', currency: 'INR' })}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                            {/* this row will not appear when printing */}
                            <div className="row no-print">
                                <div className="col-12">
                                    <a href="invoice-print.html" rel="noopener" target="_blank" className="btn btn-default"><i className="fas fa-print" /> Print</a>
                                    <button type="button" className="btn btn-success float-right"><i className="far fa-credit-card" /> Submit
                                        Payment
                                    </button>
                                    <button type="button" className="btn btn-primary float-right" style={{ marginRight: 5 }}>
                                        <i className="fas fa-download" /> Generate PDF
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* /.invoice */}
                    </div>{/* /.col */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}


        </div>
    </div>
    );
}

export default ViewPurchaseOrder;