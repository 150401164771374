import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
  const [authData, setAuthData] = useState(() => {
    const savedAuthData = localStorage.getItem('authData');
    return savedAuthData ? JSON.parse(savedAuthData) : null;
  });

  const login = (decodedToken) => {
    setAuthData(decodedToken);
    localStorage.setItem('authData', JSON.stringify(decodedToken));
  };

  const logout = () => {
    navigate('/login');
    setAuthData(null);
    localStorage.removeItem('authData');

  };

  return (
    <AuthContext.Provider value={{ authData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
