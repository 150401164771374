import { configureStore } from "@reduxjs/toolkit";
import customerReducer  from "./Slices/crmCustomerSlice";

const store = configureStore({
    reducer: {
        crmCustomers: customerReducer,
    },
});

export default store;
