import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";
import { supplierColumns } from '../../CommonComp/TableComponent/EntityTables/SupplierTblColumns';
import TableComponent from '../../CommonComp/TableComponent/TableComponent';
import { InputGroup, FormControl, Button, Spinner } from 'react-bootstrap';
import '../../App.css';
import { toast } from 'sonner';
// import { get, post } from '../../APIHandler';
import { deleteSupplierById, getSuppliersList } from '../../service/SupplierService';

function Suppliers() {
    const navigate = useNavigate();
    const [supplierData, setSupplierData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        fetchSupplierData();
    }, []);

    useEffect(() => {
        applySearch();
    }, [searchQuery]);

    const fetchSupplierData = async () => {
        try {
            setLoading(true);
            const data = await getSuppliersList();
            setSupplierData(data);
            setFilteredData(data);
        } catch (error) {
            setError(error);
            toast.error("Failed to load suppliers.");
        } finally {
            setLoading(false);
        }
    };

    const applySearch = () => {
        const filtered = supplierData.filter(supplier =>
            supplier.supplierName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            supplier.supplierCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
            supplier.panNumber.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const addSupplier = (e) => {
        e.preventDefault();
        navigate("/add_supplier");
    };

    const handleView = (supplierId) => {
        navigate(`/supplier_master/view/${supplierId}`);
    };

    const handleEdit = (supplierId) => {
        navigate(`/supplier_master/edit/${supplierId}`);
    };

    const handleDelete = async (supplierId) => {
        try {
            await deleteSupplierById(supplierId);
            setSupplierData(prevData => prevData.filter(supplier => supplier.supplierId !== supplierId));
            setFilteredData(prevData => prevData.filter(supplier => supplier.supplierId !== supplierId));
            toast.success("Supplier Deleted Successfully");
        } catch (error) {
            console.error("Failed to delete supplier", error);
            toast.error("Failed to delete supplier.");
        }
    };

    const handleToggleChange = (supplierId, value) => {
        setFilteredData(prevData =>
            prevData.map(row =>
                row.supplierId === supplierId ? { ...row, active: value } : row
            )
        );
    };

    return (
        <div className="content-wrapper">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h2>Supplier</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-2">
                                    <InputGroup className="col-md-10 w-25">
                                        <FormControl
                                            placeholder="Search by Name, Code, PAN"
                                            value={searchQuery}
                                            onChange={handleSearch}
                                            className='d-flex'
                                            style={{ width: '50%', flex: '0 auto' }}
                                        />
                                    </InputGroup>
                                    <div className="col-md-2 text-right">
                                        <Button onClick={addSupplier} className="btn-success">Add Supplier</Button>
                                    </div>
                                </div>
                                {loading ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <>
                                        {error && <p className="text-danger text-center">Failed to load data.</p>}
                                        <TableComponent
                                            columns={supplierColumns(handleToggleChange)}
                                            data={filteredData}
                                            idAccessor="supplierId"
                                            onView={handleView}
                                            onEdit={handleEdit}
                                            onDelete={handleDelete}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Suppliers;
