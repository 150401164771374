import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { API_URL } from '../../CommonComp/APIConstants';
import TableComponent from '../../CommonComp/TableComponent/TableComponent';
import { customerColumns } from '../../CommonComp/TableComponent/EntityTables/CustomerTblColumns';
import './style.css'
import { toast } from 'sonner';
import { deleteCustomer, getCustomerList } from '../../service/CustomerService';


function Customer() {

    const navigate = useNavigate();

    const [customerData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const addCustomer = (e) => {
        e.preventDefault();
        navigate("/add_customer");
    }

    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetchCustomerData();
    }, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount

    const fetchCustomerData = async () => {
        try {
            const data = await getCustomerList();  // Use service function to fetch customers
            setData(data);
        } catch (error) {
            console.error("Failed to fetch customer data", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };



    const handleView = (customerId) => {
        navigate(`/customer_master/view/${customerId}`);
    };

    const handleEdit = (customerId) => {
        navigate(`/customer_master/edit/${customerId}`);
    };

    const handleDelete = async (customerId) => {
        try {
            await deleteCustomer(customerId);
            setData(prevData => prevData.filter(customer => customer.customerId !== customerId));
            toast.success("Customer deleted successfully");
        } catch (error) {
            console.error("Failed to delete customer", error);
            toast.error("Failed to delete customer.");
        }
    };





    return (
        <div>
            <div className="content-wrapper">
                <div className="container-fluid">

                <div class="row mb-2">
                        <div class="col-sm-6">
                            <h2>Customer</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className='card'>
                                {/* <div className="card-header">
                                    <h3 className="card-title">Customers</h3>
                                </div> */}
                                <div className="card-body" >

                                    <div className="row">
                                        <div className="col-md-11">
                                        </div>
                                        <div className="col-md-1">
                                            <button  style={{ marginBottom: '10px' }} type="button" onClick={addCustomer} class="btn btn-block btn-success">Add</button>
                                        </div>
                                    </div>
                                    <TableComponent
                                        columns={customerColumns}
                                        data={customerData}
                                        idAccessor="customerId"
                                        onView={handleView}
                                        onEdit={handleEdit}
                                        onDelete={handleDelete}
                                    ></TableComponent>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </div>








            </div>
        </div>
    );

}

export default Customer;