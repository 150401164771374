export const supplierColumns = (handleToggleChange) => [
  {
    Header: 'Name',
    accessor: 'supplierName',
  },
  {
    Header: 'Code',
    accessor: 'supplierCode',
  },
  {
    Header: 'GST',
    accessor: 'gstNumber',
  },
  {
    Header: 'PAN',
    accessor: 'panNumber',
  },
  {
    Header: 'Phone Number',
    accessor: 'phonenumber',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ value }) => <a href={`mailto:${value}`}>{value}</a>,
  },
  {
    Header: 'Active',
    accessor: 'active',
    Cell: ({ value, row }) => (
      <label className="toggle-switch">
        <input
          type="checkbox"
          checked={value}
          onChange={() => handleToggleChange(row.supplierId, !value)}
        />
        <span className="toggle-slider"></span>
      </label>
    ),
  },

];
