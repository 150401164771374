import React, { useState, useEffect, useRef } from 'react';
import './common_dropdowns.css';

const CustomerDropdown = ({ customers = [], onSelectCustomer }) => {
    const [selectedCustomer, setSelectedCustomer] = useState({ customerName: 'All Customers', customerId: null });
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCustomers, setFilteredCustomers] = useState(customers);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const dropdownRef = useRef();

    const handleCustomerSelect = (customer) => {
        setSelectedCustomer(customer);
        setIsDropdownOpen(false);
        if (onSelectCustomer) {
            onSelectCustomer(customer?.customerId || null);
        }
    };

    const handleSearchChange = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchTerm(searchValue);

        const filtered = customers.filter((customer) =>
            customer.customerName.toLowerCase().includes(searchValue)
        );
        setFilteredCustomers(filtered);
    };

    useEffect(() => {
        setFilteredCustomers(customers);
    }, [customers]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='dropdown-container' ref={dropdownRef}>
            <label>Select Customer</label>
            <div className="dropdown">
                <div className="dropdown-toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                    {selectedCustomer ? selectedCustomer.customerName : 'Select Customer'}
                </div>

                {isDropdownOpen && (
                    <div className="dropdown-menu">
                        <input
                            type="text"
                            placeholder="Search Customer"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="search-dropdown mb-2"
                        />

                        <ul className="dropdown-list list-unstyled mb-0">
                            <li
                                onClick={() => handleCustomerSelect({ customerName: 'All Customers', customerId: null })}
                                className="dropdown-item"
                                style={{ cursor: 'pointer' }}
                            >
                                All Customers
                            </li>

                            {filteredCustomers.length > 0 ? (
                                filteredCustomers.map((customer) => (
                                    <li
                                        key={customer.customerId}
                                        onClick={() => handleCustomerSelect(customer)}
                                        className="dropdown-item"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {customer.customerName}
                                    </li>
                                ))
                            ) : (
                                <li className="dropdown-item disabled">No customers found</li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomerDropdown;
