import React from 'react'
import './inventorysidebar.css'

function InventorySidebar() {
  return (
    <div className='inventory-sidebar'>
        <h4>Production</h4>
        <ul>
            <li>Manage Date Range</li>
            <li>Manage Payment Status</li>
            <li>Manage Payment Type</li>
            <li>Manage Customer</li>
        </ul>
    </div>
  )
}

export default InventorySidebar